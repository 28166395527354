import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import Container from "../../../layout/Container";

const AccordionContainer = styled.div`
  width: 100%;
  margin: 30px auto;
`;

const AccordionItem = styled.div`
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
`;

const AccordionHeader = styled.div`
  padding: 15px;
  cursor: pointer;
  background-color: #f4f4f4;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
  &:hover {
    background-color: #e2e2e2;
  }
`;

const AccordionContent = styled.div`
  padding: 15px;
  display: ${(props) => (props.isOpen ? "block" : "none")};
  border-top: 1px solid #ddd;
  background-color: #fff;
  transition: max-height 0.2s ease-out;
`;

const List = ({ data, index }) => {
  const [openIndex, setOpenIndex] = useState(null);
  const { i18n } = useTranslation();

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const getLocalizedText = (item, field) => {
    const localizedField = `${field}_${i18n.language}`;
    const defaultField = `${field}_uz`;
    return item[localizedField] || item[defaultField];
  };

  return (
    <AccordionContainer>
      <AccordionItem>
        <AccordionHeader onClick={() => toggleAccordion(index)}>
          {getLocalizedText(data, "question")}
        </AccordionHeader>
        <AccordionContent isOpen={openIndex === index}>
          {getLocalizedText(data, "answers")}
        </AccordionContent>
      </AccordionItem>
    </AccordionContainer>
  );
};

export default List;
