import { Link } from "react-router-dom";
import classes from "./Breadcrumbs.module.scss";
const Breadcrumbs = ({ items }) => {
  return (
    <nav className={classes.breadcrumbs}>
      <ul>
        {items.map((item, index) => (
          <li key={index}>
            {item.link ? (
              <Link to={item.link}>{item.name}</Link>
            ) : (
              <span>{item.name}</span>
            )}
            {index < items.length - 1 && (
              <span className={classes.separator}>/</span>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Breadcrumbs;
