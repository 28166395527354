import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classes from "./Menu.module.scss";

const Menu = () => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <div className={classes.menu}>
      <div className={classes.title}>{t("AboutParty")}</div>
      <div className={classes.item}>
        <Link
          to="/party-history"
          className={`${classes.items} ${
            location.pathname === "/party-history" ? classes.active : ""
          }`}
        >
          {t("ThePartyHistoriy")}
        </Link>
        <Link
          to="/political-council"
          className={`${classes.items} ${
            location.pathname === "/political-council" ? classes.active : ""
          }`}
        >
          {t("partyboss")}
        </Link>
        <Link
          to="/election-program"
          className={`${classes.items} ${
            location.pathname === "/election-program" ? classes.active : ""
          }`}
        >
          {t("ElectionProgram")}
        </Link>
        <Link
          to="/announcements"
          className={`${classes.items} ${
            location.pathname === "/announcements" ? classes.active : ""
          }`}
        >
          {t("Announcement")}
        </Link>
      </div>
    </div>
  );
};

export default Menu;
