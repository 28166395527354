import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import Container from "../../layout/Container";
import Donate from "../../components/Donate";
import classes from "./Sponsorship.module.scss";

const Sponsorship = () => {
  const { t } = useTranslation();
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");

  const handleAmountClick = (amount) => setSelectedAmount(amount);
  const handleCustomAmount = (customAmount) => setSelectedAmount(customAmount);
  const handlePhoneNumberChange = (event) => setPhoneNumber(event.target.value);
  const handlePaymentMethodChange = (method) => setPaymentMethod(method);

  const validatePaymentMethod = () => {
    if (!paymentMethod) {
      alert("Tulov turini tanlang.");
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    if (validatePaymentMethod()) {
      console.log("Submitting form with payload:", {
        amount: selectedAmount,
        phone_number: phoneNumber,
        paymentMethod,
      });
    }
  };

  const payload = {
    amount: selectedAmount,
    phone_number: phoneNumber,
    donation_type: "donat",
  };

  return (
    <>
      <Container className={classes.sponsorship}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{t("Donate")}</title>
          <link rel="canonical" href="http://mysite.com/example" />
        </Helmet>
        <h1 className={classes.sponsorship__title}>
          {t("SocialDemocraticPartyofUzbekistanAdolat")}
        </h1>
        <div className={classes.sponsorship__block}>
          <Donate
            paymentMethod={paymentMethod}
            payload={payload}
            sum={selectedAmount}
            setSelectedAmount={setSelectedAmount}
            handleCustomAmount={handleCustomAmount}
            phoneNumber={phoneNumber}
            handlePhoneNumberChange={handlePhoneNumberChange}
            onSubmit={handleSubmit}
          />

          <div className={classes.sponsorship__payment}>
            {[5000, 10000, 15000, 25000, 0].map((amount) => (
              <p
                key={amount}
                className={
                  classes["sponsorship__payment_price"] +
                  (selectedAmount === amount
                    ? ` ${classes["sponsorship__payment_price_active"]}`
                    : "")
                }
                onClick={() => handleAmountClick(amount)}
              >
                {amount === 0 ? `${t("Other")}` : `${amount} ${t("Sum")}`}
              </p>
            ))}
          </div>
          <div className={classes.sponsorship__info}>
            <div className={classes.sponsorship__payme}>
              <label className={classes["image-radio"]}>
                <input
                  type="radio"
                  name="paymentMethod"
                  value="donations"
                  checked={paymentMethod === "donations"}
                  onChange={(e) => handlePaymentMethodChange(e.target.value)}
                  className={classes["image-radio-input"]}
                  required
                />
                <img
                  width={200}
                  className={classes.sponsorship__payme_logo}
                  src="https://turonbank.uz/bitrix/templates/main/images/cards/payments/payme.png"
                  alt="donations"
                />
              </label>
              <label className={classes["image-radio"]}>
                <input
                  type="radio"
                  name="paymentMethod"
                  value="click-donation"
                  checked={paymentMethod === "click-donation"}
                  onChange={(e) => handlePaymentMethodChange(e.target.value)}
                  className={classes["image-radio-input"]}
                  required
                />
                <img
                  width={200}
                  className={classes.sponsorship__payme_logo}
                  src="https://api.logobank.uz/media/logos_png/Click-01.png"
                  alt="click-donation"
                />
              </label>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Sponsorship;
