import React, { useState } from "react";
import classes from "./Input.module.scss";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Subscribe = ({ initialValue }) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState(initialValue || "");
  const [status, setStatus] = useState("");
  const handleChange = (event) => setInputValue(event.target.value.trim());
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!inputValue) {
      setStatus("error");
      return;
    }

    const formData = new FormData();
    formData.append("email", inputValue);

    try {
      const response = await axios.post(
        "https://api.adolat.uz/subscribed_email/",
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            "X-CSRFTOKEN":
              "K9hTgrWoAIbWtCWiAuOCCAUXXA74Lh1BeTfCbekPpQhCj9mULiHDXIcAgzfmb0eZ",
          },
        }
      );

      if (response.status === 201 || response.status === 200) {
        setStatus("success");
        setInputValue("");
      } else {
        setStatus("error");
      }
    } catch (error) {
      console.error("Error:", error);
      setStatus("error");
    }
    setTimeout(() => {
      setStatus("");
    }, 3000);
  };

  const successStyle = status === "success" ? { color: "blue" } : null;
  const errorStyle = status === "error" ? { color: "#da251c" } : null;

  return (
    <div className={classes["subscribe"]}>
      <form className={classes["subscribe__form"]} onSubmit={handleSubmit}>
        <input
          className={classes["subscribe__input"]}
          type="email"
          id="simple-input"
          placeholder={t("EntereceEmail")}
          value={inputValue}
          required
          onChange={handleChange}
        />
        <input
          type="submit"
          value={t("SubmitEmail")}
          className={classes["subscribe__button"]}
        />
      </form>
      <div>
        {status === "success" && (
          <p style={successStyle}>{t("Subscription successful!")}</p>
        )}
        {status === "error" && (
          <p style={errorStyle}>
            {t("Subscription failed. Please try again.")}
          </p>
        )}
      </div>
    </div>
  );
};

export default Subscribe;
