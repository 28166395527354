import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Container from "../../layout/Container";
import storeLogo from "../../images/storelogo.png";
import classes from "./Navbar.module.scss";
import axios from "axios";

const Navbar = ({ onClose }) => {
  const { t, i18n } = useTranslation();
  const [election, setElection] = useState({
    election_uz: "",
    election_ru: "",
    election_en: "",
  });
  useEffect(() => {
    const handleGetData = async () => {
      try {
        const res = await axios.get("https://api.adolat.uz/about/");
        if (res.data.length > 0) {
          const data = res.data[0];
          setElection({
            election_uz: data.election_uz || "",
            election_ru: data.election_ru || "",
            election_en: data.election_en || "",
          });
        }
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };

    handleGetData();
  }, []);

  const getLocalized = (item, field) => {
    const localizedField = `${field}_${i18n.language}`;
    const defaultField = `${field}_uz`;
    return item[localizedField] || item[defaultField];
  };

  return (
    <div data-aos="fade-down" className={classes["navbar"]}>
      <Container className={classes["navbar__content"]}>
        <div>
          <ul className={classes["navbar__list"]}>
            <li className={classes["navbar__label"]}>{t("AboutTheSite")}</li>
            <li>
              <Link
                className={classes["navbar__link"]}
                to="/party-history"
                onClick={onClose}
              >
                {t("ThePartyHistoriy")}
              </Link>
            </li>
            <li>
              <Link
                className={classes["navbar__link"]}
                to="/political-council"
                onClick={onClose}
              >
                {t("partyboss")}
              </Link>
            </li>
            <li>
              <Link
                className={classes["navbar__link"]}
                to="/party-projects"
                onClick={onClose}
              >
                {t("PartyProjects")}
              </Link>
            </li>
            <li>
              <Link
                className={classes["navbar__link"]}
                to="/contact"
                onClick={onClose}
              >
                {t("Contact")}
              </Link>
            </li>
          </ul>
          <ul className={classes["navbar__list"]}>
            <li className={classes["navbar__label"]}>
              {t("ThePartyStructures")}
            </li>
            <li>
              <Link
                className={classes["navbar__link"]}
                to="/political-councils"
                onClick={onClose}
              >
                {t("ThePoliticalCouncil")}
              </Link>
            </li>
            <li>
              <Link
                className={classes["navbar__link"]}
                to="/committee"
                onClick={onClose}
              >
                {t("TheExecutiveCommittee")}
              </Link>
            </li>
            <li>
              <Link
                className={classes["navbar__link"]}
                to="/commission"
                onClick={onClose}
              >
                {t("TheInspectionCommission")}
              </Link>
            </li>
            <li>
              <Link
                className={classes["navbar__link"]}
                to="/central-apparatus"
                onClick={onClose}
              >
                {t("TheCentralApparatus")}
              </Link>
            </li>
          </ul>
          <ul className={classes["navbar__list"]}>
            <li className={classes["navbar__label"]}>{t("OurWings")}</li>
            <li>
              <Link
                className={classes["navbar__link"]}
                to="/wings/youth"
                onClick={onClose}
              >
                {t("TheYouthLaw")}
              </Link>
            </li>
            <li>
              <Link
                className={classes["navbar__link"]}
                to="/wings/women"
                onClick={onClose}
              >
                {t("TheWomensLaw")}
              </Link>
            </li>
          </ul>
          <ul className={classes["navbar__list"]}>
            <li className={classes["navbar__label"]}>{t("ThePressService")}</li>
            <li>
              <Link
                className={classes["navbar__link"]}
                to="/news"
                onClick={onClose}
              >
                {t("TheNews")}
              </Link>
            </li>
            <li>
              <Link
                className={classes["navbar__link"]}
                to="/announcements"
                onClick={onClose}
              >
                {t("Announcements")}
              </Link>
            </li>
            <li>
              <Link
                className={classes["navbar__link"]}
                to="/faq"
                onClick={onClose}
              >
                {t("faq")}
              </Link>
            </li>
            <li>
              <Link
                className={classes["navbar__link"]}
                to="/most-read-news"
                onClick={onClose}
              >
                {t("MostRead")}
              </Link>
            </li>
          </ul>
          <ul className={classes["navbar__list"]}>
            <li className={classes["navbar__label"]}>{t("Parliament")}</li>
            <li>
              <Link
                className={classes["navbar__link"]}
                to="/appointed-senators"
                onClick={onClose}
              >
                {t("AppointedSenators")}
              </Link>
            </li>
            <li>
              <Link
                className={classes["navbar__link"]}
                to="/eleced-senators"
                onClick={onClose}
              >
                {t("ElectedSenators")}
              </Link>
            </li>
            <li>
              <Link
                className={classes["navbar__link"]}
                to="/deputs"
                onClick={onClose}
              >
                {t("LegislativeChamber")}
              </Link>
            </li>
            <li>
              <Link
                className={classes["navbar__link"]}
                to="/local-deputs"
                onClick={onClose}
              >
                {t("LocalCouncilDeputies")}
              </Link>
            </li>
          </ul>
          <ul className={classes["navbar__list"]}>
            <li className={classes["navbar__label"]}>{t("TheElection")}</li>
            <li>
              <Link
                className={classes["navbar__link"]}
                to="/election"
                onClick={onClose}
              >
                {getLocalized(election, "election")}
              </Link>
            </li>
            <li>
              <Link
                className={classes["navbar__link"]}
                to="/election-program"
                onClick={onClose}
              >
                {t("camprog")}
              </Link>
            </li>
            <li>
              <Link
                className={classes["navbar__link"]}
                to="/candidates"
                onClick={onClose}
              >
                {t("condidantes")}
              </Link>
            </li>
          </ul>
        </div>
        <a
          href="https://adolat24.uz/"
          target="_blank"
          className={classes["navbar__logos"]}
          onClick={onClose}
          rel="noreferrer"
        >
          <img src={storeLogo} alt="Store Logo" />
        </a>
      </Container>
    </div>
  );
};

export default Navbar;
