import React from "react";
import foto from "../../images/floweroflogo.png";
import "./Loader.css";

const Loader = ({ isLoading }) => {
  if (!isLoading) {
    return null;
  }
  return (
    <div className="loader-main">
      <span className="loader"></span>
      <img src={foto} alt="Loading" />
    </div>
  );
};

export default Loader;
