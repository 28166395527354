import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const CardContainer = styled.div`
  display: flex;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 100%;
  max-width: 900px;
  max-height: 350px;
  height: 100%;
  margin: 20px auto;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.02);
  }

  @media (max-width: 638px) {
    flex-direction: column;
    max-height: 550px;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  overflow: hidden;
  max-width: 300px;
  width: 100%;
  max-height: 350px;

  @media (max-width: 638px) {
    width: 100%;
    max-width: 100%;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  padding: 10px;
  object-fit: cover;
  object-position: top;
`;

const InfoContainer = styled.div`
  flex: 2;
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

const Name = styled.h2`
  font-size: 1.8rem;
  color: #74767b;
  margin: 10px 0;

  @media (max-width: 786px) {
    font-size: 1.2rem;
  }
`;

const DetailSection = styled.div`
  margin: 20px 0;
`;

const DetailTitle = styled.h3`
  font-size: 1.2rem;
  color: #74767b;
  margin-bottom: 5px;
`;

const Detail = styled.p`
  font-size: 1rem;
  color: #74767b;
  margin: 0 0 10px 0;
`;

const Card = ({ data }) => {
  const { t, i18n } = useTranslation();

  function getLocalizedData(item, field) {
    const localizedField = `${field}_${i18n.language}`;
    const defaultField = `${field}_uz`;
    return item[localizedField] || item[defaultField];
  }

  return (
    <CardContainer>
      <ImageContainer>
        <Image src={data.image} alt={getLocalizedData(data, "title")} />
      </ImageContainer>
      <InfoContainer>
        <div>
          <Name>{getLocalizedData(data, "title")}</Name>
        </div>
        <DetailSection>
          <DetailTitle>{t("LeaderLabelEducation")}</DetailTitle>
          <Detail>{getLocalizedData(data, "position")}</Detail>
        </DetailSection>
      </InfoContainer>
    </CardContainer>
  );
};

export default Card;
