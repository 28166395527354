import DOMPurify from "dompurify";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Container from "../../layout/Container";
import Menu from "../../components/Menu";
import classes from "./PartyCandidatesDnamic.module.scss";

const PartyCandidatesDnamic = () => {
  const [data, setData] = useState(null);
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
    const handleGetOneData = async () => {
      try {
        const res = await axios.get(`https://api.adolat.uz/party_leader/${id}`);
        setData(res.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    handleGetOneData();
  }, [id]);

  if (!data) {
    return <div>Loading...</div>;
  }

  function getLocalizedData(item, field) {
    const localizedField = `${field}_${i18n.language}`;
    const defaultField = `${field}_uz`;
    return item[localizedField] || item[defaultField];
  }
  const decodeHtml = (html) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };

  const HTMLContent = ({ content }) => {
    const decodedContent = decodeHtml(content);
    const sanitizedContent = DOMPurify.sanitize(decodedContent);

    return (
      <div
        className="custom-html-content"
        style={{
          fontSize: "18px",
          lineHeight: "1.5",
          color: "#74767b",
          fontFamily: `"Montserrat", sans-serif`,
        }}
        dangerouslySetInnerHTML={{ __html: sanitizedContent }}
      />
    );
  };

  return (
    <Container className={classes["container"]}>
      <div className={classes["full"]}>
        <div className={classes["w80"]}>
          <div className={classes["dFlex"]}>
            <div className={classes["w30"]}>
              <img
                className={classes["userPic"]}
                src={data.image}
                alt={getLocalizedData(data, "title")}
              />
            </div>
            <div className={classes["w70"]}>
              <h1 className={classes["title"]}>
                {getLocalizedData(data, "title")}
              </h1>
              <div className={classes["cardBody"]}>
                <div className={classes["item"]}>
                  <span>{t("LeaderLabelPosition")}</span>
                  <p>{getLocalizedData(data, "position")}</p>
                </div>
                <div className={classes["item"]}>
                  <span>{t("LeaderLabelBirthday")}</span>
                  <p>{data.birthdate}</p>
                </div>
                <div className={classes["item"]}>
                  <span>{t("LeaderLabelPlaceOfBirth")}</span>
                  <p>{getLocalizedData(data, "place_of_birth")}</p>
                </div>
                <div className={classes["item"]}>
                  <span>{t("LeaderLabelNationality")}</span>
                  <p>{getLocalizedData(data, "national")}</p>
                </div>
                <div className={classes["item"]}>
                  <span>{t("LeaderLabelEducation")}</span>
                  <p>{getLocalizedData(data, "education")}</p>
                </div>
                <div className={classes["item"]}>
                  <span>{t("LeaderLabelSpeciality")}</span>
                  <p>{getLocalizedData(data, "speciality")}</p>
                </div>
                <div className={classes["item"]}>
                  <span>{t("LeaderLabelLanguages")}</span>
                  <p>{getLocalizedData(data, "knowlang")}</p>
                </div>
                <div className={classes["item"]}>
                  <span>{t("LeaderLabelEmail")}</span>
                  <p>{data.email}</p>
                </div>
              </div>
            </div>
          </div>
          <div className={classes["about"]}>
            <h2 className={classes["additionalTitle"]}>
              {t("LeaderLabelAbout")}
            </h2>
            <br />
            <HTMLContent content={getLocalizedData(data, "about")} />
          </div>
        </div>
        <div className={classes.w20}>
          <Menu />
        </div>
      </div>
    </Container>
  );
};

export default PartyCandidatesDnamic;
