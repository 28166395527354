import React from "react";
import { Routes, Route } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import AOS from "aos";
import AnnouncementsDynamic from "./pages/AnnouncementsDynamic";
import Home from "./pages/Home";
import News from "./pages/News";
import PartyProjectDetails from "./pages/PartyProjectsDeatils";
import NewsPage from "./pages/NewsPage";
import PartyCandidatesDnamic from "./pages/PartyCandidatesDnamic";
import Contact from "./pages/Contact";
import PartyProjects from "./pages/PartyProjects";
import PartyHistory from "./pages/PartyHistory";
import Deputs from "./pages/Deputs";
import Announcements from "./pages/Announcements";
import Election from "./pages/Election";
import Faq from "./pages/Faq";
import PoliticalCouncil from "./pages/PoliticalCouncil";
import Sponsorship from "./pages/SponsorshipPage";
import Wings from "./pages/Wings";
import ElectionDynamic from "./pages/ElectionDynamic";
import ElectionProgram from "./pages/ElectionProgram";
import CentralApparatus from "./pages/CentralApparatus";
import Store from "./pages/Store";
import Cart from "./pages/Cart";
import Committee from "./pages/Committee";
import Commission from "./pages/Commission";
import AppointedSenators from "./pages/AppointedSenators";
import ElecedSenators from "./pages/ElectedSenators";
import LocalDeputets from "./pages/LocalDeputets";
import TheMostReadNews from "./pages/TheMostReadNews";
import PoliticalCouncilPartTwo from "./pages/PoliticalCouncilPartTwo";
import i18next from "./i18next";
import Header from "./components/Header";
import HeaderTop from "./components/HeaderTop";
import Footer from "./components/Footer";
import "aos/dist/aos.css";
import "./index.scss";
import Candidates from "./pages/Candidates";

const App = () => {
  React.useEffect(() => {
    AOS.init({
      disable: false,
      startEvent: "DOMContentLoaded",
      initClassName: "aos-init",
      animatedClassName: "aos-animate",
      useClassNames: false,
      disableMutationObserver: false,
      debounceDelay: 50,
      throttleDelay: 99,
      offset: 120,
      delay: 0,
      duration: 1000,
      easing: "ease",
      once: false,
      mirror: false,
      anchorPlacement: "top-bottom",
    });
  }, []);

  return (
    <div className="App">
      <I18nextProvider i18n={i18next}>
        <HeaderTop />
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/candidates" element={<Candidates />} />
          <Route path="/election" element={<Election />} />
          <Route path="/committee" element={<Committee />} />
          <Route path="/central-apparatus" element={<CentralApparatus />} />
          <Route path="/news" element={<NewsPage />} />
          <Route
            path="/political-councils"
            element={<PoliticalCouncilPartTwo />}
          />
          <Route path="/political-council" element={<PoliticalCouncil />} />
          <Route path="/store" element={<Store />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/most-read-news" element={<TheMostReadNews />} />
          <Route path="/announcements" element={<Announcements />} />
          <Route path="/deputs" element={<Deputs />} />
          <Route path="/appointed-senators" element={<AppointedSenators />} />
          <Route path="/eleced-senators" element={<ElecedSenators />} />
          <Route path="/commission" element={<Commission />} />
          <Route path="/local-deputs" element={<LocalDeputets />} />
          <Route path="/election-program" element={<ElectionProgram />} />
          <Route path="/election" element={<Election />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/party-projects" element={<PartyProjects />} />
          <Route path="/sponsorship" element={<Sponsorship />} />
          <Route path="/party-history" element={<PartyHistory />} />

          <Route path="/wings/:component" element={<Wings />} />
          <Route path="/election/:id" element={<ElectionDynamic />} />
          <Route
            path="/party-candidates/:id"
            element={<PartyCandidatesDnamic />}
          />
          <Route path="/party-projects/:id" element={<PartyProjectDetails />} />
          <Route path="/announcements/:id" element={<AnnouncementsDynamic />} />
          <Route path="/news/:id" element={<News />} />
        </Routes>
        <Footer />
      </I18nextProvider>
    </div>
  );
};

export default App;
