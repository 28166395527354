import { Helmet } from "react-helmet";
import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import Container from "../../layout/Container";
import Title from "../../components/Title";
import images from "./images";

const Block = styled(Container)`
  margin: 50px auto;

  @media (max-width: 786px) {
    margin: 0 auto;
  }
`;

const SubTitle = styled.h2`
  font-size: 20px;
  color: #74767b;
  text-align: center;
  margin: 30px auto 0 auto;
  max-width: 800px;
  width: 100%;

  @media (max-width: 786px) {
    font-size: 16px;
  }
`;

const Content = styled.div`
  margin-top: 20px;
  & img {
    width: 100%;
  }
`;

const Candidates = () => {
  const { t } = useTranslation();
  //t("condidantesText")
  return (
    <Block>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("condidantes")}</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <Title>{t("condidantes")}</Title>
      <SubTitle
        dangerouslySetInnerHTML={{
          __html:
            "O'ZBEKISTON “ADOLAT” SDPdan <br/> O'ZBEKISTON OLIY MAJLISI QONUNCHILIK PALATASIGA BIR <br/> MANDATLI SAYLOV OKRUGIDAN KORSATILGAN NOMZODLAR",
        }}
      />
      <Content>
        {images.map((image, index) => (
          <img key={index} src={image} alt="" />
        ))}
      </Content>
    </Block>
  );
};

export default Candidates;
