import { useDispatch } from "react-redux";
import React, { useState } from "react";
import { FaShoppingCart } from "react-icons/fa";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { setColor, setSize, addToCart } from "../../../redux/cart";
import classes from "./Card.module.scss";

const Card = ({ data, select }) => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const [activeColors, setActiveColors] = useState(data.selectedColors || []);
  const [activeSizes, setActiveSizes] = useState(data.selectedSizes || []);

  // Toggle color selection
  const handleColorClick = (color) => {
    const updatedColors = activeColors.includes(color)
      ? activeColors.filter((c) => c !== color) // remove if already selected
      : [...activeColors, color]; // add if not selected

    setActiveColors(updatedColors);
    dispatch(setColor({ id: data.id, colors: updatedColors }));
  };

  // Toggle size selection
  const handleSizeClick = (size) => {
    const updatedSizes = activeSizes.includes(size)
      ? activeSizes.filter((s) => s !== size) // remove if already selected
      : [...activeSizes, size]; // add if not selected

    setActiveSizes(updatedSizes);
    dispatch(setSize({ id: data.id, sizes: updatedSizes }));
  };

  const handleToCart = (item) => {
    dispatch(
      addToCart({
        ...item,
        selectedColors: activeColors,
        selectedSizes: activeSizes,
      })
    );
  };

  const getLocalizedText = (item, field) => {
    const localizedField = `${field}_${i18n.language}`;
    const defaultField = `${field}_uz`;
    return item[localizedField] || item[defaultField] || "";
  };

  const formatNumber = (number) =>
    number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  const colors = data.color_en.split(",").filter(Boolean);
  const sizes = data.size ? data.size.split(",").filter(Boolean) : [];

  return (
    <div className={classes.card}>
      <div className={classes.card__imgContainer}>
        <img
          className={classes.card__img}
          src={data.image}
          alt={getLocalizedText(data, "title")}
        />
      </div>
      <div className={classes.card__details}>
        <p className={classes.card__name}>{getLocalizedText(data, "title")}</p>
        <p>
          {i18n.language === "ru"
            ? `В наличии ${data.quantity}`
            : `Sotuvda ${data.quantity} dona bor`}
        </p>
        <div className={classes.card__info}>
          <p className={classes.card__discount}>
            {formatNumber(data.sale)} {t("Sum")}
          </p>
          <p className={classes.card__price}>
            {formatNumber(data.price)} {t("Sum")}
          </p>
        </div>
        <div className={classes.card__colorSizeContainer}>
          <div className={classes.card__colors}>
            {colors.length > 0 ? (
              colors.map((color, i) => {
                return (
                  <button
                    disabled={data.quantity === 0}
                    key={i}
                    style={{ background: `${color}` }}
                    className={classNames(classes.card__color, {
                      [classes.card__colorActive]: activeColors.includes(color),
                    })}
                    onClick={() => handleColorClick(color)}
                  ></button>
                );
              })
            ) : (
              <p>No colors available</p>
            )}
          </div>
          <div className={classes.card__sizes}>
            {sizes.length > 0 ? (
              sizes.map((size, i) => (
                <button
                  disabled={data.quantity === 0}
                  key={i}
                  className={classNames(classes.card__size, {
                    [classes.card__sizeActive]: activeSizes.includes(size),
                  })}
                  onClick={() => handleSizeClick(size)}
                >
                  {size}
                </button>
              ))
            ) : (
              <p>No sizes available</p>
            )}
          </div>
        </div>
        <button
          disabled={data.quantity === 0}
          onClick={() => handleToCart(data)}
          className={classNames(classes.card__cart, {
            [classes.card__cartSelect]: select,
          })}
        >
          <FaShoppingCart />
        </button>
      </div>
    </div>
  );
};

export default Card;
