import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import DOMPurify from "dompurify";
import { Helmet } from "react-helmet";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import { FaFacebook, FaTelegram } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import Menu from "../../components/Menu";
import Container from "../../layout/Container";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import classes from "./ElectionDynamic.module.scss";

const ElectionDynamic = () => {
  const [data, setData] = useState({});
  const { id } = useParams();
  const { i18n } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleGetData = async () => {
      try {
        const res = await axios.get(`https://api.adolat.uz/saylov/${id}`);
        setData(res.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    handleGetData();
  }, [id]);

  const currentUrl = window.location.href;
  const shareText = "Check out this news article!";

  const getLocalizedText = (item, field) => {
    const localizedField = `${field}_${i18n.language}`;
    const defaultField = `${field}_uz`;
    return item[localizedField] || item[defaultField];
  };

  const decodeHtml = (html) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };

  const HTMLContent = ({ content }) => {
    const decodedContent = decodeHtml(content);
    const sanitizedContent = DOMPurify.sanitize(decodedContent);

    return (
      <div
        className="custom-html-content"
        style={{
          fontSize: "18px",
          lineHeight: "1.5",
          color: "#74767b",
          fontFamily: `"Montserrat", sans-serif`,
        }}
        dangerouslySetInnerHTML={{ __html: sanitizedContent }}
      />
    );
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data.title_uz || "News"}</title>
        <link rel="canonical" href={currentUrl} />
      </Helmet>
      <Container className={classes["news__content"]}>
        <div className={classes["news__left"]}>
          {data ? (
            <div className={classes["news__card"]} key={data.id}>
              <div className={classes["news__intro"]}>
                <h2 className={classes["news__title"]}>
                  {getLocalizedText(data, "title")}
                </h2>
              </div>
              <div>
                <Swiper
                  modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                  slidesPerView={1}
                  navigation
                  loop={true}
                  speed={1500}
                  autoplay={true}
                >
                  {data.media &&
                    data.media.map((item) => (
                      <SwiperSlide
                        className={classes["news__slider"]}
                        key={item.id}
                      >
                        <img
                          className={classes["news__imgs"]}
                          src={item.media}
                          alt=""
                        />
                      </SwiperSlide>
                    ))}
                </Swiper>
              </div>
              <HTMLContent
                className={classes["news__text"]}
                content={getLocalizedText(data, "description")}
              />
            </div>
          ) : (
            <p>Loading...</p>
          )}
          <div className={classes["news__info"]}>
            <ul className={classes["news__socials"]}>
              <li>
                <a
                  href={`https://t.me/share/url?url=${encodeURIComponent(
                    currentUrl
                  )}&text=${encodeURIComponent(shareText)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaTelegram />
                </a>
              </li>
              <li>
                <a
                  href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                    currentUrl
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebook />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className={classes["news__right"]}>
          <div className={classes["content"]}>
            <Menu />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ElectionDynamic;
