import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { TiArrowSortedDown } from "react-icons/ti";
import "./Dropdown.css";

const Dropdown = () => {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const languages = [
    { code: "uz", label: "Oʻz" },
    { code: "ru", label: "Рус" },
    { code: "en", label: "En" },
  ];

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleLanguageSelect = (languageCode) => {
    i18n.changeLanguage(languageCode);
    localStorage.setItem("i18nextLng", languageCode);
    setIsOpen(false);
  };

  return (
    <div className="dropdown">
      <button onClick={toggleDropdown} className="dropdown-toggle">
        {t("switchLanguage")}
        <TiArrowSortedDown
          className={`dropdown-icon ${isOpen ? "rotate" : ""}`}
        />
      </button>
      {isOpen && (
        <ul className="dropdown-menu">
          {languages.map((lang) => (
            <li
              key={lang.code}
              onClick={() => handleLanguageSelect(lang.code)}
              className="dropdown-item"
            >
              {lang.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
