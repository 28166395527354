import React from "react";
import { FaArrowRight } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import { FaRegCalendarAlt } from "react-icons/fa";
import DOMPurify from "dompurify";
import { Link } from "react-router-dom";
import classes from "./Card.module.scss";

const Card = ({ data }) => {
  const { t, i18n } = useTranslation();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const getFirstWords = (text, wordCount) => {
    if (!text) return "";
    const words = text.split(" ");
    return (
      words.slice(0, wordCount).join(" ") +
      (words.length > wordCount ? "..." : "")
    );
  };

  const getLocalizedText = (item, field) => {
    const localizedField = `${field}_${i18n.language}`;
    const defaultField = `${field}_uz`;
    return item[localizedField] || item[defaultField];
  };

  const decodeHtml = (html) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };

  const HTMLContent = ({ content }) => {
    const decodedContent = decodeHtml(content);
    const sanitizedContent = DOMPurify.sanitize(decodedContent);

    return (
      <div
        className="custom-html-content"
        style={{
          fontSize: "18px",
          lineHeight: "1.5",
          color: "#74767b",
          fontFamily: `"Montserrat", sans-serif`,
        }}
        dangerouslySetInnerHTML={{ __html: sanitizedContent }}
      />
    );
  };

  return (
    <div className={classes["card"]}>
      <Link to={`/party-projects/${data.id}`} className={classes["card__link"]}>
        <h5 className={classes["card__title"]}>{data.year}</h5>
      </Link>
      <p className={classes["card__line"]}></p>
      <HTMLContent
        content={getFirstWords(getLocalizedText(data, "description"), 20)}
      />
      <span>
        <FaRegCalendarAlt /> {formatDate(data.created_at)}
      </span>
      <div className={classes["card__bottom"]}>
        <Link
          to={`/party-projects/${data.id}`}
          className={classes["card__link"]}
        >
          {t("more")} <FaArrowRight />
        </Link>
      </div>
    </div>
  );
};

export default Card;
