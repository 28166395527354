import React from "react";
import Container from "../../layout/Container";
import News from "../../components/News";
import classes from "./NewsPage.module.scss";

const NewsPage = () => {
  return (
    <Container className={classes["container"]}>
      <News />
    </Container>
  );
};

export default NewsPage;
