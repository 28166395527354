import React from "react";

import classes from "./Title.module.scss";
import classNames from "classnames";

const Title = ({ children, className, dataaos }) => {
  return (
    <h2 className={classNames(classes["title"], className)} data-aos={dataaos}>
      {children}
    </h2>
  );
};

export default Title;
