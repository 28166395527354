import { useTranslation } from "react-i18next";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import Container from "../../layout/Container";
import Card from "./components/Card";
import Title from "../../components/Title";
import Breadcrumbs from "../../components/Breadcrumbs";
import Menu from "../../components/Menu";

import classes from "./AppointedSenators.module.scss";

const AppointedSenators = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleGetData = async () => {
      const res = await axios.get("https://api.adolat.uz/appointed-senates/");
      setData(res.data);
    };
    handleGetData();
  }, []);

  const breadcrumbItems = [
    { name: t("HomePage"), link: "/" },
    { name: t("AppointedSenators") },
  ];
  const sortedData = [...data].sort((a, b) => {
    if (a.id === 10) return -1;
    if (b.id === 10) return 1;
    return 0;
  });

  if (!Array.isArray(sortedData)) return null;

  return (
    <Container className={classes["container"]}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("AppointedSenators")}</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <Breadcrumbs items={breadcrumbItems} />
      <div className={classes["row"]}>
        <div className={classes["w-70"]}>
          <>
            <Title className={classes["mb-3"]}>{t("AppointedSenators")}</Title>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {sortedData.map((item) => (
                <Card key={item.id} data={item} />
              ))}
            </div>
          </>
        </div>
        <div className={classes["w-30"]}>
          <Menu />
        </div>
      </div>
    </Container>
  );
};

export default AppointedSenators;
