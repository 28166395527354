import React, { useEffect, useState } from "react";
import { TbWorld } from "react-icons/tb";
import { CiWarning } from "react-icons/ci";
import { FaPhoneAlt } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Container from "../../layout/Container";
import classes from "./HeaderTop.module.scss";
import styled, { keyframes } from "styled-components";

const moveRightToLeft = keyframes`
  0% {
    transform: translateX(100%);
  }
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
`;

const TextWrapper = styled.div`
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const AnimatedText = styled.div`
  display: inline-block;
  white-space: nowrap;
  font-size: 18px;
  transition: ease-out 0.8s keyframes;
  animation: ${moveRightToLeft} 8s linear infinite;
`;

const HeaderTop = () => {
  const { t } = useTranslation();
  const [phoneData, setPhoneData] = useState({
    phone_number: "",
  });
  const texts = [t("testverion")];

  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 8000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const handleGetPhonenumber = async () => {
      const res = await axios.get("https://api.adolat.uz/organizations/");
      if (res.data.length > 0) {
        const data = res.data[0];
        setPhoneData({
          phone_number: data.phone_number || "",
        });
      }
    };
    handleGetPhonenumber();
  }, []);

  return (
    <div className={classes["header-top"]}>
      <Container className={classes["header-top__content"]}>
        <a
          rel="noreferrer"
          className={classes["header-top__link"]}
          href="https://old.adolat.uz/"
          target="_blank"
        >
          <TbWorld />
          {t("oldSite")}
        </a>
        <TextWrapper>
          <AnimatedText>
            <CiWarning /> {texts[currentTextIndex]}
          </AnimatedText>
        </TextWrapper>
        <a className={classes["header-top__link"]} href="tel:+998712884654">
          <FaPhoneAlt />
          {phoneData.phone_number}
        </a>
      </Container>
    </div>
  );
};

export default HeaderTop;
