import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IoMdClose } from "react-icons/io";
import Container from "../../layout/Container";
import Title from "../../components/Title";
import CartItems from "./components/CartItems";
import Breadcrumbs from "../../components/Breadcrumbs";
import classes from "./Cart.module.scss";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import axios from "axios";

const Cart = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const { cart } = useSelector((state) => state);
  const itemsContnent = Object.values(cart || {});
  const count = itemsContnent.reduce((acc, item) => acc + item.quantity, 0);
  const [paymentUrl, setPaymentUrl] = useState("");
  const cartEmpty = itemsContnent.length === 0;
  const total = itemsContnent.reduce(
    (acc, item) => acc + item.price * item.quantity,
    0
  );

  const handleClear = () => {
    setOpen((prev) => !prev);
  };

  const formatNumber = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    location: "",
    paymentMethod: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageClick = (paymentMethod, paymenurl) => {
    setFormData({
      ...formData,
      paymentMethod,
    });
    setPaymentUrl(paymenurl);
  };

  const payment = {
    amount: total,
    phone_number: formData.phoneNumber,
    donation_type: "order",
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.paymentMethod) {
      alert(t("Please select a payment method."));
      return;
    }

    const payload = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      location: formData.location,
      payment_method: formData.paymentMethod,
      phone_number: formData.phoneNumber,
      items_data: itemsContnent.map((item) => ({
        product_id: item.id,
        quantity: item.quantity,
      })),
    };

    try {
      const response = await axios.post(
        "https://api.adolat.uz/order/",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setFormData({
        firstName: "",
        lastName: "",
        phoneNumber: "",
        location: "",
        paymentMethod: "",
      });
      setOpen(false);
      setModalOpen(false);
    } catch (error) {
      console.error("Error submitting order:", error);
    }

    try {
      const handlePayment = async () => {
        const res = await axios.post(
          `https://api.adolat.uz/payment/${paymentUrl}/`,
          payment
        );
        window.open(res.data.payment_url);
        setTimeout(() => {
          localStorage.removeItem("cart");
        }, 3000);
      };
      handlePayment();
    } catch (error) {
      console.log("Error Payment", error);
    }
  };

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [open]);

  const breadcrumbItems = [
    { name: t("HomePage"), link: "/" },
    { name: t("Cart") },
  ];

  return (
    <Container className={classes["cart"]}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("Cart")}</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <Breadcrumbs items={breadcrumbItems} />
      {cartEmpty ? (
        <div>
          <Title>{t("CartIsEmpty")}</Title>
        </div>
      ) : (
        <>
          <Title>{t("Cart")}</Title>
          {itemsContnent.map((card) => (
            <CartItems key={card.id} data={card} />
          ))}
        </>
      )}
      {!cartEmpty && (
        <div className={classes["cart__info"]}>
          <p>
            {t("AllProducts")} {count}
          </p>
          <p>
            {formatNumber(total)} {t("Total")}
          </p>
        </div>
      )}
      {!cartEmpty && (
        <div className={classes["cart__btn"]}>
          <button onClick={handleClear}>{t("Payment")}</button>
        </div>
      )}
      {open && (
        <div className={classes["cart__payment"]}>
          <div className={classes["payment-container"]}>
            <div className={classes["payment-container__item"]}>
              <button onClick={handleClear}>
                <IoMdClose />
              </button>
            </div>
            <form onSubmit={handleSubmit} className={classes["payment-form"]}>
              <h2>{t("PaymentDetails")}</h2>
              <div className={classes["payment-form__field"]}>
                <label htmlFor="firstName">{t("reqname")}</label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className={classes["payment-form__field"]}>
                <label htmlFor="lastName">{t("Surname")}</label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className={classes["payment-form__field"]}>
                <label htmlFor="phoneNumber">{t("LeaderLabelPhone")}</label>
                <input
                  type="tel"
                  id="phoneNumber"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className={classes["payment-form__field"]}>
                <label htmlFor="location">{t("Location")}</label>
                <input
                  type="text"
                  id="location"
                  name="location"
                  value={formData.location}
                  onChange={handleChange}
                  required
                />
              </div>
              <button type="submit" className={classes["payment-form__submit"]}>
                {t("Payment")}
              </button>
              <div className={classes["modal-images"]}>
                <label className={classes["image-radio"]}>
                  <input
                    type="radio"
                    name="paymentMethod"
                    value="payme"
                    checked={formData.paymentMethod === "payme"}
                    onChange={() => handleImageClick("payme", "donations")}
                    className={classes["image-radio-input"]}
                  />
                  <img
                    src="https://turonbank.uz/bitrix/templates/main/images/cards/payments/payme.png"
                    alt="Payme"
                    className={classes["image-radio-img"]}
                    height={100}
                  />
                </label>
                <label className={classes["image-radio"]}>
                  <input
                    type="radio"
                    name="paymentMethod"
                    value="click"
                    checked={formData.paymentMethod === "click"}
                    onChange={() => handleImageClick("click", "click-donation")}
                    className={classes["image-radio-input"]}
                  />
                  <img
                    src="https://click.uz/click/images/click-white.jpg"
                    alt="Click"
                    className={classes["image-radio-img"]}
                    height={100}
                  />
                </label>
              </div>
            </form>
          </div>
        </div>
      )}
    </Container>
  );
};

export default Cart;
