import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import DOMPurify from "dompurify";
import Container from "../../layout/Container";
import Menu from "../../components/Menu";
import Title from "../../components/Title";
import classes from "./PartyProjectDetails.module.scss";

const PartyProjectDetails = () => {
  const { id } = useParams();
  const [project, setProject] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { i18n } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchProject = async () => {
      try {
        const response = await axios.get(
          `https://api.adolat.uz/party_projects/${id}`
        );
        setProject(response.data);
      } catch (error) {
        console.error("Error fetching project data:", error);
        setError("Failed to load project details.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchProject();
  }, [id]);

  const getLocalizedText = (item, field) => {
    const localizedField = `${field}_${i18n.language}`;
    const defaultField = `${field}_uz`;
    return item[localizedField] || item[defaultField];
  };
  const decodeHtml = (html) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };

  const HTMLContent = ({ content }) => {
    const decodedContent = decodeHtml(content);
    const sanitizedContent = DOMPurify.sanitize(decodedContent);

    return (
      <div
        className="custom-html-content"
        style={{
          fontSize: "18px",
          lineHeight: "1.5",
          color: "#74767b",
          fontFamily: `"Montserrat", sans-serif`,
        }}
        dangerouslySetInnerHTML={{ __html: sanitizedContent }}
      />
    );
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <Container className={classes.container}>
      <div className={classes.container__main}>
        <div className={classes.content}>
          <div className={classes.content__top}>
            <Title>{project?.year || "No Name"}</Title>
          </div>
          <HTMLContent
            className={classes.description}
            content={getLocalizedText(project, "description")}
          />
        </div>
      </div>
      <div className={classes.container__menu}>
        <Menu />
      </div>
    </Container>
  );
};

export default PartyProjectDetails;
