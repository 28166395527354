import React, { useEffect, useState } from "react";
import { FaInstagram, FaFacebook, FaTelegram, FaYoutube } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Container from "../../layout/Container";
import logo from "../../images/logo1.png";
import classes from "./Footer.module.scss";
import { Link } from "react-router-dom";

const Footer = () => {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState({});
  const [partyLeaderName, setPartyLeaderName] = useState({
    party_leader_name: "",
    party_leader_name_ru: "",
  });
  const [socialMediaLinks, setSocialMediaLinks] = useState({
    instagram: "",
    facebook: "",
    telegram: "",
    youtube: "",
  });

  const [contacts, setContacts] = useState({
    email: "",
    location_uz: "", 
    location_ru: "",
    location_en: "",
    phone_number: null,
  });

  useEffect(() => {
    const handleGetData = async () => {
      try {
        const res = await axios.get("https://api.adolat.uz/about/");
        if (res.data.length > 0) {
          const data = res.data[0];
          setPartyLeaderName({
            party_leader_name: data.party_leader_name || "",
            party_leader_name_ru: data.party_leader_name_ru || "",
          });
          setSocialMediaLinks({
            instagram: data.instagram || "",
            facebook: data.facebook || "",
            telegram: data.telegram || "",
            youtube: data.youtube || "",
          });
        }
        setData(res.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    handleGetData();
  }, []);

  useEffect(() => {
    const handleGetData = async () => {
      const res = await axios.get("https://api.adolat.uz/about/5");
      const { email, location_uz, location_ru, location_en, phone_number } =
        res.data;
      setContacts({
        email,
        location_uz,
        location_ru,
        location_en,
        phone_number,
      });
    };
    handleGetData();
  }, []);

  const getLocalized = (item, field) => {
    const localizedField = `${field}_${i18n.language}`;
    const defaultField = `${field}_uz`;
    return item[localizedField] || item[defaultField] || item[field];
  };

  return (
    <footer className={classes["footer"]}>
      <Container className={classes["footer__content"]}>
        <div className={classes["footer__content-item"]}>
          <div className={classes["footer__content-item_location"]}>
            <Link to="/" className={classes["footer__logo"]}>
              <img src={logo} alt="Logo" />
            </Link>
            <p className={classes["footer__text"]}>{t("firstadolat")}</p>
          </div>
          <div className={classes["footer__location"]}>
            <p className={classes["footer__label"]}>{t("adres")}</p>
            <p className={classes["footer__text"]}>
              {getLocalized(contacts, "location")}
            </p>
          </div>
          <div className={classes["footer__social"]}>
            <div className={classes["footer__social-leader"]}>
              <p>
                {t("robaxon")}{" "}
                {getLocalized(partyLeaderName, "party_leader_name")}
              </p>
              <ul>
                <li>
                  <a
                    href={socialMediaLinks.instagram}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <FaInstagram />
                  </a>
                </li>
                <li>
                  <a
                    href={socialMediaLinks.facebook}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <FaFacebook />
                  </a>
                </li>
                <li>
                  <a
                    href={socialMediaLinks.telegram}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <FaTelegram />
                  </a>
                </li>
                <li>
                  <a
                    href={socialMediaLinks.youtube}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <FaYoutube />
                  </a>
                </li>
              </ul>
            </div>
            <div className={classes["footer__social-party"]}>
              <p>{t("wesocial")}</p>
              <ul>
                <li>
                  <a
                    href="https://www.instagram.com/adolat_sdp/?hl=ru"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaInstagram />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.facebook.com/sdpu.adolat"
                  >
                    <FaFacebook />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://t.me/adolat_uz"
                  >
                    <FaTelegram />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.youtube.com/@adolat_sdp-j5y"
                  >
                    <FaYoutube />
                  </a>
                </li>
              </ul>
            </div>
            <div className={classes["footer__contact"]}>
              <a
                className={classes["footer__text"]}
                href={`tel:${contacts.phone_number}`}
              >
                {contacts.phone_number}
              </a>
              <a
                className={classes["footer__text"]}
                href={`mailto:${contacts.email}`}
              >
                {contacts.email}
              </a>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
