import styled from "styled-components";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useEffect, useState } from "react";
import Container from "../../layout/Container";
import Title from "../../components/Title";

const ElectionContainer = styled(Container)`
  padding-left: 0;
  padding-right: 0;
  margin-top: 50px;
  margin-bottom: 100px;
`;

const ElectionBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
  margin-top: 30px;

  @media (min-width: 2090px) {
    & > div {
      min-width: 20%;
    }
  }

  @media (min-width: 1920px) {
    & > div {
      width: 32%;
    }
  }

  @media (max-width: 1910px) {
    & > div {
      width: 32%;
    }
  }

  @media (max-width: 1610px) {
    & > div {
      width: 48%;
    }
  }

  @media (max-width: 1200px) {
    & > div {
      width: 48%;
    }
  }

  @media (max-width: 1000px) {
    justify-content: flex-start;

    & > div {
      width: 48%;
    }
  }

  @media (max-width: 650px) {
    justify-content: center;

    & > div {
      width: 97%;
    }
  }
`;

const NewsItem = styled.div`
  position: relative;
  width: 433px;
  height: 600px;
  box-shadow: 0px 0px 8px 1px rgb(220, 220, 220);
  display: flex;
  animation-duration: 1.2s;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  transition: color 0.5s;

  &:hover .body .body-link a {
    color: #da251c;
  }

  figure {
    width: 100%;
    height: 330px;
    margin: 0;
    padding: 0;
    background: #fff;
    overflow: hidden;
    position: relative;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      object-position: top;
      transform: scale(1);
      transition: transform 0.3s ease-in-out;
    }

    &:hover img {
      transform: scale(1.1);
    }
  }

  .body {
    padding: 10px 20px;
    height: 47%;
    display: flex;
    flex-direction: column;

    h3 {
      margin-top: 15px;
      max-height: 100%;
      color: #74767b;
      font-size: 20px;
      font-weight: bold;

      @media (max-width: 1740px) {
        height: 95px;
      }

      @media (max-width: 1610px) {
        height: 70px;
      }

      @media (max-width: 1000px) {
        font-size: 18px;
      }

      @media (max-width: 650px) {
        font-size: 22px;
      }
    }

    p {
      max-height: 120px;
      height: 100%;
      margin-top: 15px;
      font-size: 18px;
      text-align: justify;
      color: #74767b;

      @media (max-width: 1000px) {
        font-size: 14px;
      }

      @media (max-width: 650px) {
        font-size: 16px;
      }
    }

    span {
      position: relative;
      margin: 15px 0;
      color: #74767b;
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 400;

      @media (max-width: 1000px) {
        margin-top: 0;
        font-size: 12px;
      }

      @media (max-width: 650px) {
        font-size: 16px;
      }
    }

    .body-link {
      margin-top: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;

      a {
        color: #3474bc;
        font-size: 18px;
        font-weight: 500;
        text-decoration: none;
        display: flex;
        align-items: center;
        gap: 5px;
        transition: color 0.5s;
        margin-bottom: 5px;

        @media (max-width: 1000px) {
          font-size: 14px;
        }

        @media (max-width: 650px) {
          font-size: 16px;
        }

        &:hover {
          color: #da251c;
        }
      }
    }
  }
`;

const Election = () => {
  const [data, setData] = useState([]);
  const { i18n } = useTranslation();
  const [election, setElection] = useState({
    election_uz: "",
    election_ru: "",
    election_en: "",
  });

  useEffect(() => {
    const handleGetData = async () => {
      try {
        const res = await axios.get("https://api.adolat.uz/about/");
        if (res.data.length > 0) {
          const data = res.data[0];
          setElection({
            election_uz: data.election_uz || "",
            election_ru: data.election_ru || "",
            election_en: data.election_en || "",
          });
        }
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };

    handleGetData();
  }, []);

  const getLocalized = (item, field) => {
    switch (i18n.language) {
      case "uz":
        return item[`${field}_uz`];
      case "ru":
        return item[`${field}_ru`];
      case "en":
        return item[`${field}_en`];
      default:
        return item[`${field}_uz`];
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleGetData = async () => {
      const res = await axios.get("https://api.adolat.uz/saylov/");
      setData(res.data);
    };
    handleGetData();
  }, []);

  return (
    <ElectionContainer>
      <Title>{getLocalized(election, "election")}</Title>
      <ElectionBlock>
        {data.map((item, index) => (
          <Card key={item.id} index={index} data={item} />
        ))}
      </ElectionBlock>
    </ElectionContainer>
  );
};

export default Election;

const Card = ({ data, index }) => {
  const { t, i18n } = useTranslation();
  const durationValues = [1000, 2000, 3000];

  const getLocalizedText = (item, field) => {
    const localizedField = `${field}_${i18n.language}`;
    const defaultField = `${field}_uz`;
    return item[localizedField] || item[defaultField];
  };

  const truncateText = (text, wordLimit = 25) => {
    const words = text.split(" ");
    if (words.length <= wordLimit) {
      return text;
    }
    return words.slice(0, wordLimit).join(" ") + "...";
  };

  return (
    <NewsItem data-aos="fade-up" data-aos-duration={durationValues[index % 3]}>
      <Link to={`/election/${data.id}`}>
        <figure>
          <img src={data.poster_media} alt="" />
        </figure>
        <div className="body">
          <h3>{truncateText(getLocalizedText(data, "title"), 7)}</h3>
          <p>{truncateText(getLocalizedText(data, "description"), 25)}</p>
          <div className="body-link">
            <Link to={`/election/${data.id}`}>
              {t("more")} <FaArrowRight />
            </Link>
          </div>
        </div>
      </Link>
    </NewsItem>
  );
};
