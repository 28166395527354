import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import axios from "axios";

import Title from "../../../../components/Title";
import Container from "../../../../layout/Container";
import MapSvg from "../../../Home/components/Map/MapSvg";

import "./Map.css";

const region = [
  { id: 30, state: "andijon" },
  { id: 41, state: "xorazm" },
  { id: 31, state: "buxoro" },
  { id: 38, state: "surxondaryo" },
  { id: 1, state: "qashqadaryo" },
  { id: 34, state: "navoiy" },
  { id: 36, state: "samarqand" },
  { id: 33, state: "jizzax" },
  { id: 28, state: "sirdaryo" },
  { id: 39, state: "toshkent-v" },
  { id: 40, state: "toshkent" },
  { id: 32, state: "fargona" },
  { id: 35, state: "namangan" },
  { id: 29, state: "qoraqalpoq" },
];

const Map = () => {
  const { t, i18n } = useTranslation();
  const [currentRegion, setCurrentRegion] = useState(region[10].state);
  const [data, setData] = useState([]);

  useEffect(() => {
    const handleGetData = async () => {
      const res = await axios.get("https://api.adolat.uz/contact-map/");
      setData(res.data);
    };
    handleGetData();
  }, []);

  const handleClick = (regionName) => {
    setCurrentRegion(regionName);
  };

  const getLocalizedText = (item, field) => {
    const localizedField = `${field}_${i18n.language}`;
    const defaultField = `${field}_uz`;
    return item[localizedField] || item[defaultField];
  };

  return (
    <Container className={"container"}>
      <div className="content_wrapper contact_container">
        <Title className="title">{t("ContactRegions")}</Title>
        <div className="contact_page_wrapper">
          <div className="territorial_divisions_map">
            <MapSvg handleClick={handleClick} activePath={currentRegion} />
          </div>
          <div className="parent-container">
            <div className="territory_info">
              {data.length &&
                data
                  .filter((data) => data.region === currentRegion)
                  .map((territorialData) => (
                    <div
                      className="territorial_divisioins_deputy"
                      key={territorialData.id}
                    >
                      <h1 className="territorial_divisions_deputy_title">
                        {getLocalizedText(territorialData, "state")}
                      </h1>
                      <p className="territorial_divisions_deputy_text email">
                        {territorialData?.email}
                      </p>
                      <p className="territorial_divisions_deputy_text">
                        {territorialData?.phone}
                      </p>
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Map;
