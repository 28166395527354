import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Container from "../../layout/Container";
import Title from "../../components/Title";

const BlockContainer = styled(Container)`
  margin: 50px auto;
`;

const BlockContainerTitle = styled(Title)`
  @media (max-width: 678px) {
    font-size: 24px;
  }
  @media (max-width: 499px) {
    font-size: 18px;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
`;

const List = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0;

  & li {
    margin: 30px;
    max-width: 340px;
    width: 100%;
  }
`;

const RegionLink = styled.a`
  width: 100%;
  background: #3474bc;
  border-radius: 22px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
`;

const LocalDeputets = () => {
  const [pdfs, setPdfs] = useState([]);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const fetchPdfs = async () => {
      try {
        const res = await axios.get("https://api.adolat.uz/region-pdf/");
        setPdfs(res.data);
      } catch (error) {
        console.error("Error fetching PDFs:", error);
      }
    };
    fetchPdfs();
  }, []);

  const getLocalizedText = (item, field) => {
    const localizedField = `${field}_${i18n.language}`;
    const defaultField = `${field}_uz`;
    return item[localizedField] || item[defaultField];
  };

  return (
    <BlockContainer>
      <BlockContainerTitle>{t("LocalCouncilDeputies")}</BlockContainerTitle>
      <Content>
        <List>
          {pdfs.map((item) => {
            return (
              <li key={item.id}>
                <RegionLink
                  href={item.pdf}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {getLocalizedText(item, "region")}
                </RegionLink>
              </li>
            );
          })}
        </List>
      </Content>
    </BlockContainer>
  );
};

export default LocalDeputets;
