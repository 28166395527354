import React, { useState, useEffect } from "react";
import classes from "./Donate.module.scss";
import axios from "axios";
import { useTranslation } from "react-i18next";

const Donate = ({
  sum,
  phoneNumber,
  handlePhoneNumberChange,
  payload,
  paymentMethod,
}) => {
  const [customAmount, setCustomAmount] = useState(sum.toString());
  const [phoneInput, setPhoneInput] = useState(
    phoneNumber ? phoneNumber : "+998"
  );
  const { t, i18n } = useTranslation();
  useEffect(() => {
    setCustomAmount(sum.toString());
  }, [sum]);
  useEffect(() => {
    setPhoneInput(phoneNumber ? phoneNumber : "+998");
  }, [phoneNumber]);
  const handleAmountChange = (event) => setCustomAmount(event.target.value);
  const handlePhoneNumberChangeWrapper = (event) => {
    let value = event.target.value.replace(/[^\d]/g, "");
    if (value.length > 12) {
      value = value.slice(0, 12);
    }
    if (!value.startsWith("998")) {
      value = `998${value.slice(3)}`;
    }
    setPhoneInput(`+${value}`);
    const formattedValue = `+${value}`;
    if (typeof handlePhoneNumberChange === "function") {
      handlePhoneNumberChange({ target: { value: formattedValue } });
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (customAmount === "0") {
      alert("Please enter an amount greater than zero.");
      return;
    }
    if (!paymentMethod) {
      alert(
        i18n.language === "ru"
          ? "Выберите способ оплаты"
          : "Tulov turini tanlang."
      );
      return;
    }
    try {
      const updatedPayload = { ...payload, phoneNumber: phoneInput };

      const res = await axios.post(
        `https://api.adolat.uz/payment/${paymentMethod}/`,
        updatedPayload
      );
      const { payment_url } = res.data;
      window.open(payment_url);
    } catch (error) {
      console.error("Donate error:", error);
    }
  };

  return (
    <div className={classes.donate}>
      <form className={classes.donate__form} onSubmit={handleSubmit}>
        <input
          type="number"
          value={customAmount}
          onChange={handleAmountChange}
          className={classes.donate__input}
          placeholder="Other amount"
          required
        />
        <input
          type="text"
          value={phoneInput}
          onChange={handlePhoneNumberChangeWrapper}
          className={classes.donate__input}
          placeholder="+998"
          required
        />
        <button type="submit" className={classes.donate__button}>
          {t("Payment")}
        </button>
      </form>
    </div>
  );
};

export default Donate;
