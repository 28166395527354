import React, { useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Container from "../../../../layout/Container";
import Title from "../../../../components/Title";
import classes from "./Form.module.scss";

const Form = () => {
  const [formData, setFormData] = useState({
    full_name: "",
    address: "",
    phone_number: "",
    message: "",
  });
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      await axios.post("https://api.adolat.uz/contacts/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRFTOKEN":
            "GMW9zJc7cuJ6peG3rO2CHTM0xPmCu4jSgq4Ey0SEzzexXpTbaKExqf8BUNMv09bG",
        },
      });
      setSuccess(true);
      setFormData({
        full_name: "",
        address: "",
        phone_number: "",
        message: "",
      });
    } catch (error) {
      console.error("Error submitting form:", error);
      setError("An error occurred while submitting the form.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className={classes["container"]}>
      <Title>{t("ReceptionofAdolat")}</Title>
      <form className={classes["form"]} onSubmit={handleSubmit}>
        <input
          className={classes["form__input"]}
          type="text"
          name="full_name"
          value={formData.full_name}
          onChange={handleChange}
          placeholder={t("FormFullname")}
          required
        />
        <input
          className={classes["form__input"]}
          type="text"
          name="address"
          value={formData.address}
          onChange={handleChange}
          placeholder={t("ResidentialAddress")}
          required
        />
        <input
          className={classes["form__input"]}
          type="text"
          name="phone_number"
          value={formData.phone_number}
          onChange={handleChange}
          placeholder={t("TelNumber")}
          required
        />
        <textarea
          className={classes["form__textarea"]}
          name="message"
          value={formData.message}
          onChange={handleChange}
          placeholder={t("Message")}
          required
        ></textarea>
        <button
          type="submit"
          className={classes["form__btn"]}
          disabled={loading}
        >
          {t("SendMessage")}
        </button>
        {error && <p className={classes["form__error"]}>{error}</p>}
        {success && (
          <p className={classes["form__success"]}>{t("MessageSuccessfully")}</p>
        )}
      </form>
    </Container>
  );
};

export default Form;
