import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaRegCalendarAlt, FaArrowRight, FaEye } from "react-icons/fa";
import { Helmet } from "react-helmet";
import ReactPaginate from "react-paginate";
import DOMPurify from "dompurify";
import { CiSearch } from "react-icons/ci";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import Container from "../../layout/Container";
import Title from "../Title";
import classes from "./News.module.scss";
import NewsType from "./NewsType";
import Region from "./Region";
import Breadcrumbs from "../../components/Breadcrumbs";
import "highlight.js/styles/default.css";
import "./pagination.css";
import "./style.css";

const News = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedRegion, setSelectedRegion] = useState("all");
  const [selectedCategory, setSelectedCategory] = useState({
    id: "all",
    name: t("AllCategory"),
  });
  const [openDropdown, setOpenDropdown] = useState(null);
  const itemsPerPage = location.pathname === "/" ? 3 : 6;
  console.log(data, 12);

  useEffect(() => {
    const handleGetData = async (page = 0) => {
      try {
        const offset = page * itemsPerPage;
        const newsResponse = await axios.get(
          `https://api.adolat.uz/news/?limit=${itemsPerPage}&offset=${offset}`
        );
        setData(newsResponse.data.results);
        setTotalItems(newsResponse.data.count);
      } catch (error) {
        console.log("Error fetching news data:", error);
      }
    };

    handleGetData(currentPage);
  }, [currentPage, itemsPerPage]);

  useEffect(() => {
    if (Array.isArray(data) && data.length > 0) {
      const filtered = data
        .filter((item) => {
          const regionMatch =
            selectedRegion === "all" ||
            item.region === parseInt(selectedRegion, 10);
          const categoryMatch =
            selectedCategory.id === "all" ||
            item.category === parseInt(selectedCategory.id, 10);
          const searchMatch =
            !searchValue ||
            (getLocalizedText(item, "title") &&
              getLocalizedText(item, "title")
                .toLowerCase()
                .includes(searchValue.toLowerCase())) ||
            (getLocalizedText(item, "description") &&
              getLocalizedText(item, "description")
                .toLowerCase()
                .includes(searchValue.toLowerCase()));

          return regionMatch && categoryMatch && searchMatch;
        })
        .sort((a, b) => new Date(b.created) - new Date(a.created));

      setFilteredData(filtered);
    }
  }, [searchValue, selectedCategory, data, selectedRegion]);

  const handleSearchChange = (e) => setSearchValue(e.target.value);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
    window.scrollTo(0, 0);
  };

  const handleDropdownToggle = (dropdownName) => {
    setOpenDropdown(openDropdown === dropdownName ? null : dropdownName);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const getFirstWords = (text, wordCount) => {
    if (!text) return "";
    const cleanedText = text
      .replace(/<\/?(br|p|li|ul|ol|h[1-6])\b[^>]*>/gi, " ") // Remove specific HTML tags
      .replace(/&nbsp;/gi, " ") // Replace HTML non-breaking spaces with regular spaces
      .replace(/\s+/g, " ") // Remove extra whitespace
      .trim();
    const words = cleanedText.split(" ");
    const firstWords = words.slice(0, wordCount).join(" ");
    return words.length > wordCount ? `${firstWords}...` : firstWords;
  };

  const getLocalizedText = (item, field) => {
    const localizedField = `${field}_${i18n.language}`;
    const defaultField = `${field}_uz`;
    return item[localizedField] || item[defaultField] || t("NoData");
  };

  const decodeHtml = (html) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };

  const HTMLContent = ({ content }) => {
    const decodedContent = decodeHtml(content);
    const sanitizedContent = DOMPurify.sanitize(decodedContent);

    return (
      <div
        className="custom-html-content"
        style={{
          maxHeight: "80px",
          fontSize: "18px",
          lineHeight: "1.5",
          color: "##74767b",
          fontFamily: `"Montserrat", sans-serif`,
        }}
        dangerouslySetInnerHTML={{ __html: sanitizedContent }}
      />
    );
  };

  const paginatedNewsData = filteredData.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  const pageCount = Math.ceil(totalItems / itemsPerPage);

  const breadcrumbItems = [
    { name: t("HomePage"), link: "/" },
    { name: t("TheNews") },
  ];

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("TheNews")}</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <Container className={classes["news"]}>
        {location.pathname === "/news" && (
          <Breadcrumbs items={breadcrumbItems} />
        )}
        <div className={classes["news-title"]}>
          <Title data-aos="fade-right">{t("TheNews")}</Title>
        </div>
        <div data-aos="fade-up" className={classes["news-filter"]}>
          <div className={classes["news-full"]}>
            <NewsType
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              isOpen={openDropdown === "NewsType"}
              onToggle={() => handleDropdownToggle("NewsType")}
            />
            <Region
              selectedRegion={selectedRegion}
              setSelectedRegion={setSelectedRegion}
              isOpen={openDropdown === "Region"}
              onToggle={() => handleDropdownToggle("Region")}
            />
          </div>
          <form
            onSubmit={(e) => e.preventDefault()}
            className={classes["news-filter__form"]}
          >
            <input
              type="search"
              value={searchValue}
              onChange={handleSearchChange}
              placeholder={t("search")}
            />
            <button>
              <CiSearch />
            </button>
          </form>
        </div>
        <div className={classes["news-content"]}>
          {data
            .sort((a, b) => new Date(b.created) - new Date(a.created))
            .map((item, index) => (
              <div
                data-aos="fade-up"
                data-aos-duration={1000 + index * 1000}
                key={item.id}
                className={classNames(classes["news-content__items"])}
              >
                <Link to={`/news/${item.id}`}>
                  <figure>
                    <img
                      src={item.poster_image || "/path/to/placeholder.jpg"}
                      alt={getLocalizedText(item, "title")}
                    />
                  </figure>
                  <div className={classes["body"]}>
                    <h3>{getFirstWords(getLocalizedText(item, "title"), 5)}</h3>
                    <p className={classes["calendar"]}>
                      <FaRegCalendarAlt />
                      {item.created
                        ? formatDate(item.created)
                        : formatDate(item.created_at)}
                    </p>
                    <HTMLContent
                      content={getFirstWords(
                        getLocalizedText(item, "description"),
                        10
                      )}
                    />
                    <p className={classes["news__views"]}>
                      {item.views} <FaEye />
                    </p>
                    <Link to={`/news/${item.id}`}>
                      {t("more")} <FaArrowRight />
                    </Link>
                  </div>
                </Link>
              </div>
            ))}
        </div>
        {location.pathname === "/" ? (
          <div style={{ margin: "50px 0", textAlign: "center" }}>
            <Link className={classes["news__link"]} to="/news">
              {t("more")}
            </Link>
          </div>
        ) : (
          <div className={"pagination-container"}>
            <ReactPaginate
              previousLabel={t("back")}
              nextLabel={t("next")}
              breakLabel="..."
              pageCount={pageCount} // Total page count for pagination
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick} // Handle pagination change
              containerClassName="pagination"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              activeClassName="active"
            />
          </div>
        )}
      </Container>
    </>
  );
};

export default News;
