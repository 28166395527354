import { useSelector } from "react-redux";
import Card from "./Card";

import { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";

const Block = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 50px;
`;

const ProductItems = ({ setData }) => {
  const { cart } = useSelector((state) => state);
  setData(cart);
  const [product, setPruduct] = useState(null);
  useEffect(() => {
    const handleGetProduct = async () => {
      const res = await axios.get("https://api.adolat.uz/product/");
      setPruduct(res.data);
    };
    handleGetProduct();
  }, []);
  return (
    <Block>
      {Array.isArray(product) &&
        product.map((item) => (
          <Card key={item.id} data={item} select={item.id in cart} />
        ))}
    </Block>
  );
};

export default ProductItems;
