import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Map from "./components/Map";
import News from "../../components/News";
import Party from "../../components/Party";
import Statistics from "../../components/Statistics";
import ElectionProgram from "../../components/ElectionProgram";
import Gallery from "../../components/Gallery";
import ActivePeople from "../../components/ActivePeople";
import Projects from "../../components/Projects";
import ModalWindow from "./components/ModalWindow";
import Iframe from "./components/Iframe";
import Partners from "./components/Partners";
import Loader from "../../components/Loader";
import Slider from "../../components/Slider";

const Home = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([
          fetch("https://api.adolat.uz/news"),
          fetch("https://api.adolat.uz/about/"),
        ]);
      } catch (error) {
        console.log("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("HomePage")}</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <div style={{ overflowX: "hidden" }}>
        <Loader isLoading={isLoading} />
        <ModalWindow />
        <Slider />
        <Party />
        <News />
        <Statistics />
        <ElectionProgram />
        <Projects />
        <Map />
        <Gallery />
        <ActivePeople />
        <Iframe />
        <Partners />
      </div>
    </>
  );
};

export default Home;
