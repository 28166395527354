import { FaPlus, FaMinus } from "react-icons/fa";
import { FaRegTrashAlt } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { addOne, removeFromCart, removeOne } from "../../../../redux/cart";
import classes from "./CartItems.module.scss";

const CartItems = ({ data }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const handleClose = () => dispatch(removeFromCart(data.id));
  const handleMinus = () => dispatch(removeOne(data.id));
  const handlePlus = () => dispatch(addOne(data.id));
  const formatNumber = (number) =>
    number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  const totalPrice = data.quantity * data.price;

  const getLocalizedText = (item, field) => {
    const localizedField = `${field}_${i18n.language}`;
    const defaultField = `${field}_uz`;
    return item[localizedField] || item[defaultField] || "";
  };
  console.log(data, "cart");

  return (
    <div className={classes.cartItem}>
      <div className={classes.cartItem__img}>
        <img src={data.image} alt={data.name} />
      </div>
      <h2 className={classes.cartItem__name}>
        {getLocalizedText(data, "title")}
      </h2>
      <div className={classes.cartItem__counter}>
        <button onClick={handleMinus}>
          <FaMinus />
        </button>
        <p>{data.quantity}</p>
        <button onClick={handlePlus}>
          <FaPlus />
        </button>
      </div>
      <div className={classes.cartItem__info}>
        <p>{formatNumber(totalPrice)} {t('Sum')}</p>
        <button onClick={handleClose}>
          <FaRegTrashAlt />
        </button>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <span>{t("StoreColor")}:</span>
        {data.selectedColors &&
          data.selectedColors.map((item, index) => (
            <p
              key={index}
              style={{
                background: item,
                width: "30px",
                height: "30px",
                borderRadius: "100%",
                marginLeft: "5px",
                border: "1px solid gray",
              }}
            ></p>
          ))}
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <p>{t("StoreSize")}:</p>
        {data.selectedSizes && (
          <p
            style={{ fontSize: "18px", fontWeight: "bold", marginLeft: "5px" }}
          >
            {data.selectedSizes.join(", ")}
          </p>
        )}
      </div>
    </div>
  );
};

export default CartItems;
