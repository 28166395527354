import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import DOMPurify from "dompurify";
import axios from "axios";
import Container from "../../layout/Container";
import Title from "../Title";
import classes from "./ElectionProgram.module.scss";

const Slider = () => {
  const [data, setData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { i18n } = useTranslation();

  useEffect(() => {
    const handleGetData = async () => {
      try {
        const res = await axios.get("https://api.adolat.uz/party_structures/");
        setData(res.data || []);
      } catch (error) {
        console.log("Failed to fetch data:", error);
      }
    };

    handleGetData();
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        return (prevIndex + 1) % data.length;
      });
    }, 6000);

    return () => clearInterval(interval);
  }, [data.length]);

  const handleThumbnailClick = (index) => setCurrentIndex(index);

  const getFirstWords = (item) => item.split(" ").slice(0, 70).join(" ");

  const getLocalizedText = (item, field) => {
    const localizedField = `${field}_${i18n.language}`;
    const defaultField = `${field}_uz`;
    return item[localizedField] || item[defaultField];
  };

  const decodeHtml = (html) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };

  const HTMLContent = ({ content }) => {
    const decodedContent = decodeHtml(content);
    const sanitizedContent = DOMPurify.sanitize(decodedContent);

    return (
      <div
        className="custom-html-content"
        style={{
          fontSize: "18px",
          lineHeight: "1.5",
          color: "#74767b",
          fontFamily: `"Montserrat", sans-serif`,
        }}
        dangerouslySetInnerHTML={{ __html: sanitizedContent }}
      />
    );
  };

  if (data.length === 0) {
    return <p></p>;
  }

  return (
    <Container className={classes["slider"]}>
      <Title dataaos="fade-right">{t("camprog")}</Title>
      <div
        className={classes["slider-container"]}
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div className={classes["main-content"]}>
          <div className={classes["main-content__left"]}>
            <figure>
              <img
                className={classes["image"]}
                src={data[currentIndex].image}
                alt={getLocalizedText(data[currentIndex], "title")}
              />
            </figure>
          </div>
          <div className={classes["text-content"]}>
            <h2 className={classes["text-content__title"]}>
              {getLocalizedText(data[currentIndex], "title")}
            </h2>
            <HTMLContent
              style={{ fontSize: "18px" }}
              content={getFirstWords(
                getLocalizedText(data[currentIndex], "description")
              )}
            />
            <Link className={classes["more-link"]} to="/election-program">
              {t("more")}
            </Link>
          </div>
        </div>
        <div className={classes["sidebar"]}>
          {data.map((item, index) => (
            <div
              key={item.id}
              className={`${classes["thumbnail"]} ${
                currentIndex === index ? classes["active"] : ""
              }`}
              onClick={() => handleThumbnailClick(index)}
            >
              <div className={classes["right-col"]}>
                <img
                  className={currentIndex === index ? "active" : ""}
                  src={item.image}
                  alt={getLocalizedText(item, "title")}
                />
                <p>{getLocalizedText(item, "title")}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default Slider;
