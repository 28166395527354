import { useTranslation } from "react-i18next";
import styled from "styled-components";
import axios from "axios";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";

import Title from "../../components/Title";
import List from "./components/List";
import Container from "../../layout/Container";
import Breadcrumbs from "../../components/Breadcrumbs";

const Block = styled(Container)`
  margin-top: 50px;
`;

const Faq = () => {
  const { t } = useTranslation();
  const breadcrumbItems = [
    { name: t("HomePage"), link: "/" },
    { name: t("faq") },
  ];
  const [data, setData] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
    const handleGetData = async () => {
      const res = await axios.get("https://api.adolat.uz/faq/");
      setData(res.data);
    };
    handleGetData();
  }, []);
  return (
    <Block>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("faq")}</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <Breadcrumbs items={breadcrumbItems} />
      <Title>{t("faq")}</Title>
      {Array.isArray(data) &&
        data.map((item, index) => (
          <List key={item.id} index={index} data={item} />
        ))}
    </Block>
  );
};

export default Faq;
