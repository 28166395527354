import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import Container from "../../layout/Container";
import Title from "../../components/Title";
import classes from "./Store.module.scss";
import Breadcrumbs from "../../components/Breadcrumbs";
import ProductItems from "./components/CartItems";
import { useTranslation } from "react-i18next";

const Store = () => {
  const { t } = useTranslation();
  const [data, setData] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const breadcrumbItems = [
    { name: t("HomePage"), link: "/" },
    { name: t("ShopProducts") },
  ];

  return (
    <Container className={classes["store"]}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("ShopProducts")}</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <Breadcrumbs items={breadcrumbItems} />
      <Title>{t("ShopProducts")}</Title>
      <div>
        <ProductItems setData={setData} />
      </div>
    </Container>
  );
};

export default Store;
