import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import axios from "axios";
import DOMPurify from "dompurify";
import Breadcrumbs from "../../components/Breadcrumbs";
import Container from "../../layout/Container";
import Title from "../../components/Title";
import classes from "./ElectionProgram.module.scss";

const ElectionProgram = () => {
  const [data, setData] = useState({});
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const handleGetData = async () => {
      try {
        const res = await axios.get("https://api.adolat.uz/before_Election/");
        if (res.data.length > 0) {
          setData(res.data[0]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    handleGetData();
    window.scrollTo(0, 0);
  }, []);

  const breadcrumbItems = [
    { name: t("HomePage"), link: "/" },
    { name: t("camprog") },
  ];

  const getLocalizedText = (item, field) => {
    const localizedField = `${field}_${i18n.language}`;
    const defaultField = `${field}_uz`;
    return item[localizedField] || item[defaultField];
  };

  const decodeHtml = (html) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };

  const HTMLContent = ({ content }) => {
    const decodedContent = decodeHtml(content);
    const sanitizedContent = DOMPurify.sanitize(decodedContent);

    return (
      <div
        className="custom-html-content"
        style={{
          fontSize: "18px",
          lineHeight: "1.5",
          color: "#74767b",
          fontFamily: `"Montserrat", sans-serif`,
        }}
        dangerouslySetInnerHTML={{ __html: sanitizedContent }}
      />
    );
  };

  return (
    <Container className={classes["program"]}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("camprog")}</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <Breadcrumbs items={breadcrumbItems} />
      <Title className={classes["program__title"]}>{t("camprog")}</Title>
      <HTMLContent content={getLocalizedText(data, "description")} />
    </Container>
  );
};

export default ElectionProgram;
