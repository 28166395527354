import i18next from "../../i18next";
import { PiSpeakerHigh } from "react-icons/pi";

const TextToSpeech = () => {
  const handleSpeak = () => {
    if ("speechSynthesis" in window) {
      if (speechSynthesis.speaking) {
        speechSynthesis.cancel();
      } else {
        const textContent = document.body.innerText;
        const utterance = new SpeechSynthesisUtterance(textContent);
        const lang = i18next.language;
        if (lang === "uz") {
          utterance.lang = "uz-Uz";
        } else if (lang === "ru") {
          utterance.lang = "ru-RU";
        } else {
          utterance.lang = "en-US";
        }

        speechSynthesis.speak(utterance);
      }
    } else {
      alert("Sorry, your browser doesn't support text to speech!");
    }
  };

  return (
    <button
      onClick={handleSpeak}
      style={{
        fontSize: "30px",
        color: "#fff",
        background: "none",
        border: "none",
        cursor: "pointer",
      }}
    >
      <PiSpeakerHigh />
    </button>
  );
};

export default TextToSpeech;
