import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/Breadcrumbs";
import Container from "../../layout/Container";
import Title from "../../components/Title";
import Card from "./components/Card";
import Menu from "../../components/Menu";
import classes from "./Deputs.module.scss";

const Deputs = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);

  useEffect(() => {
    const handleGetData = async () => {
      const res = await axios.get("https://api.adolat.uz/deputies/");
      setData(res.data);
    };
    handleGetData();
  }, []);
  const breadcrumbItems = [
    { name: t("HomePage"), link: "/" },
    { name: t("deputs") },
  ];

  const sortedData = [...data].sort((a, b) => {
    if (a.id === 20) return -1;
    if (b.id === 20) return 1;
    return 0;
  });

  if (!Array.isArray(sortedData)) return null;
  const currentUrl = window.location.href;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("deputs")}</title>
        <link rel="canonical" href={currentUrl} />
      </Helmet>
      <Container className={classes["container"]}>
        <Breadcrumbs items={breadcrumbItems} />
        <Title>{t("deputs")}</Title>
        <div className={classes["row"]}>
          <div className={classes["w-70"]}>
            <div className={classes["rows"]}>
              {Array.isArray(sortedData) &&
                sortedData
                  .sort((a, b) => a.index - b.index)
                  .map((item) => <Card key={item.id} data={item} />)}
            </div>
          </div>
          <div className={classes["w-30"]}>
            <Menu />
          </div>
        </div>
      </Container>
    </>
  );
};

export default Deputs;
