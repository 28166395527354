import React, { useEffect, useState } from "react";
import { GoBellFill } from "react-icons/go";
import { IoCloseOutline } from "react-icons/io5";
import Input from "./Input/index";
import classes from "./ModalWindow.module.scss";
import Facebook from "./Social/Facebook.svg";
import Telegram from "./Social/Telegram.svg";
import Twitter from "./Social/Twitter.svg";
import Youtube from "./Social/Youtube.svg";
import { useTranslation } from "react-i18next";

const ModalWindow = () => {
  const { t } = useTranslation();
  const [state, setState] = useState(() => {
    const hasSeenModal = sessionStorage.getItem("hasSeenModal");
    return !hasSeenModal;
  });

  const toggleModal = () => {
    sessionStorage.setItem("hasSeenModal", "true");
    setState(false);
  };

  useEffect(() => {
    if (state) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [state]);

  return (
    <>
      {state ? (
        <div className={classes["home__content"]}>
          <div className={classes["modal"]}>
            <p className={classes["modal__notification"]}>
              <GoBellFill />
            </p>
            <h6 className={classes["modal__title"]}>{t("TheLatestNews")}</h6>
            <Input />
            <div className={classes["social"]}>
              <div className={classes["social-title"]}>
                {t("OurSocialMedia")}
              </div>
              <div className={classes["social-icons"]}>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.facebook.com/sdpu.adolat"
                >
                  <img src={Facebook} alt="" />
                </a>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://twitter.com/AdolatTv"
                >
                  <img src={Twitter} alt="" />
                </a>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://t.me/adolat_uz "
                >
                  <img src={Telegram} alt="" />
                </a>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.youtube.com/channel/UCkx-ijiA4qTKsjRjarAT-xA"
                >
                  <img src={Youtube} alt="" />
                </a>
              </div>
            </div>
            <button className={classes["modal__cls"]} onClick={toggleModal}>
              <IoCloseOutline />
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ModalWindow;
