import { useEffect, useState, useCallback } from "react";
import { FaArrowRight, FaEye, FaRegCalendarAlt } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import DOMPurify from "dompurify";

import Container from "../../layout/Container";
import Title from "../../components/Title";

import classes from "./Announcements.module.scss";

const ElectionContainer = styled(Container)`
  margin-top: 50px;
  margin-bottom: 100px;
`;

const ElectionBlock = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 40px;

  @media (max-width: 800px) {
    justify-content: center;
  }
`;

const Announcements = () => {
  const { t } = useTranslation();
  const [data, setData] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);

    const handleGetData = async () => {
      try {
        const res = await axios.get("https://api.adolat.uz/announcements/");
        setData(res.data);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    handleGetData();
  }, []);

  const currentUrl = window.location.href;

  return (
    <ElectionContainer>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("Announcements")}</title>
        <link rel="canonical" href={currentUrl} />
      </Helmet>
      <Title>{t("Announcements")}</Title>
      <ElectionBlock>
        {Array.isArray(data) &&
          data.map((item, index) => (
            <Card key={item.id} index={index} data={item} />
          ))}
      </ElectionBlock>
    </ElectionContainer>
  );
};

export default Announcements;

const Card = ({ data, index }) => {
  const { t, i18n } = useTranslation();

  const durationValues = useCallback(
    (index) => [1000, 2000, 3000][index % 3],
    []
  );

  const getLocalizedData = (item, field) => {
    const localizedField = `${field}_${i18n.language}`;
    const defaultField = `${field}_uz`;
    return item[localizedField] || item[defaultField];
  };

  const getFirstWords = (htmlString, wordCount) => {
    if (!htmlString) return "";
    const plainText = htmlString
      .replace(/(<([^>]+)>)/gi, " ")
      .replace(/&[^;]+;/g, " ")
      .replace(/\s+/g, " ")
      .trim();

    const words = plainText.split(" ");
    return (
      words.slice(0, wordCount).join(" ") +
      (words.length > wordCount ? "..." : "")
    );
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  };

  const decodeHtml = (html) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };

  const HTMLContent = ({ content }) => {
    const decodedContent = decodeHtml(content);
    const sanitizedContent = DOMPurify.sanitize(decodedContent);

    return (
      <div
        className="custom-html-content"
        style={{
          fontSize: "18px",
          lineHeight: "1.5",
          color: "#74767b",
          fontFamily: `"Montserrat", sans-serif`,
        }}
        dangerouslySetInnerHTML={{ __html: sanitizedContent }}
      />
    );
  };
  return (
    <div
      data-aos="fade-up"
      data-aos-duration={durationValues(index)}
      className={classes["news-content__items"]}
    >
      <Link to={`/announcements/${data.id}`}>
        <figure>
          <img
            src={data.image}
            alt={getFirstWords(getLocalizedData(data, "title"), 6)}
          />
        </figure>
        <div className={classes["body"]}>
          <h3>{getFirstWords(getLocalizedData(data, "title"), 6)}</h3>
          <p>
            {formatDate(data.created_at)} <FaRegCalendarAlt />
          </p>
          <HTMLContent
            content={getFirstWords(getLocalizedData(data, "description"), 15)}
          />
          <p className={classes["news__views"]}>
            {data.views} <FaEye />
          </p>
          <Link to={`/announcements/${data.id}`}>
            {t("more")} <FaArrowRight />
          </Link>
        </div>
      </Link>
    </div>
  );
};
