import { initReactI18next } from "react-i18next";
import i18next from "i18next";
import en from "./locales/en/language.json";
import ru from "./locales/ru/language.json";
import uz from "./locales/uz/language.json";
const savedLanguage = localStorage.getItem("i18nextLng") || "uz";
i18next.use(initReactI18next).init({
  resources: {
    ru: {
      translation: ru,
    },
    en: {
      translation: en,
    },
    uz: {
      translation: uz,
    },
  },
  lng: savedLanguage,
});

export default i18next;
