import classes from "./Slider.module.scss";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import axios from "axios";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const Slider = () => {
  const { i18n } = useTranslation();
  const [data, setData] = useState(null);
  const isImageActive =
    Array.isArray(data) &&
    data.length > 0 &&
    typeof data[0] === "object" &&
    data[0] !== null &&
    Boolean(data[0].is_image_active);

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await axios.get("https://api.adolat.uz/content/");
        setData(res.data);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };
    getData();
  }, [isImageActive]);

  console.log(isImageActive, 12);
  const getLocalizedText = (item, field) => {
    const localizedField = `${field}_${i18n.language}`;
    const defaultField = `${field}_uz`;
    return item[localizedField] || item[defaultField];
  };

  return (
    <>
      {isImageActive ? (
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
          slidesPerView={1}
          navigation
          loop={true}
          speed={2000}
          autoplay={{
            delay: 3500,
            disableOnInteraction: true,
          }}
          pagination={{ clickable: true }}
          className={classes["swiper"]}
        >
          {data.map((item, index) => (
            <SwiperSlide key={index} className={classes["swiper__inner"]}>
              <img
                className={classes["swiper__image"]}
                src={item.image}
                alt={`Slide ${index + 1}`}
              />
              <div className={classes["swiper__position"]}>
                <h1>{getLocalizedText(item, "title")}</h1>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <div className={classes["slider"]}>
          <div className={classes["slider-content"]}>
            <video autoPlay loop muted>
              <source src="/video.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
      )}
    </>
  );
};

export default Slider;
