import React, { useState, useEffect } from "react";
import { TiArrowSortedDown } from "react-icons/ti";
import classNames from "classnames";
import axios from "axios";
import { useTranslation } from "react-i18next";

import classes from "./Region.module.scss";

const Region = ({ selectedRegion, setSelectedRegion, isOpen, onToggle }) => {
  const [regions, setRegions] = useState([]);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const fetchRegions = async () => {
      try {
        const response = await axios.get("https://api.adolat.uz/regions/");
        setRegions(response.data);
      } catch (error) {
        console.error("Error fetching regions:", error);
      }
    };
    fetchRegions();
  }, []);

  const handleRegionSelect = (region) => {
    setSelectedRegion(region.id);
    onToggle();
  };

  function getRegionName(item) {
    switch (i18n.languages[0]) {
      case "uz":
        return item.name_uz;
      case "ru":
        return item.name_ru;
      case "en":
        return item.name_en;
      default:
        return item.name_uz;
    }
  }

  return (
    <div className={classes["dropdown"]}>
      <button onClick={onToggle} className={classes["dropdown-toggle"]}>
        {selectedRegion === "all"
          ? t("AllRegion")
          : getRegionName(
              regions.find((region) => region.id === selectedRegion)
            )}
        <TiArrowSortedDown
          className={classNames(
            classes["dropdown-icon"],
            isOpen ? classes["rotate"] : ""
          )}
        />
      </button>
      {isOpen && (
        <ul className={classes["dropdown-menu"]}>
          <li
            key="all"
            onClick={() => handleRegionSelect({ id: "all" })}
            className={classes["dropdown-item"]}
          >
            {t("AllRegion")}
          </li>
          {regions.map((region) => (
            <li
              key={region.id}
              onClick={() => handleRegionSelect(region)}
              className={classes["dropdown-item"]}
            >
              {getRegionName(region)}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Region;
