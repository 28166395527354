import React, { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Container from "../../../../layout/Container";
import classes from "./WorkMode.module.scss";

const WorkMode = () => {
  const { t, i18n } = useTranslation();
  const time = [
    {
      day: t("days.du"),
      time: "09:00 - 18:00",
    },
    {
      day: t("days.se"),
      time: "09:00 - 18:00",
    },
    {
      day: t("days.cho"),
      time: "09:00 - 18:00",
    },
    {
      day: t("days.pay"),
      time: "09:00 - 18:00",
    },
    {
      day: t("days.ju"),
      time: "09:00 - 18:00",
    },
    {
      day: t("days.sha"),
      time: "09:00 - 18:00",
      weak: true,
    },
    {
      day: t("days.yak"),
      time: "09:00 - 18:00",
      weak: true,
    },
  ];
  const [data, setData] = useState({
    email: "",
    location_uz: "",
    location_ru: "",
    location_en: "",
  });
  useEffect(() => {
    const handleGetData = async () => {
      const res = await axios.get("https://api.adolat.uz/about/5");
      const { email, location_uz, location_ru, location_en } = res.data;
      setData({ email, location_uz, location_ru, location_en });
    };
    handleGetData();
  }, []);

  const getLocalized = (item, field) => {
    switch (i18n.language) {
      case "uz":
        return item[`${field}_uz`];
      case "ru":
        return item[`${field}_ru`];
      case "en":
        return item[`${field}_en`];
      default:
        return item[`${field}_uz`];
    }
  };

  return (
    <Container className={classes["block"]}>
      <div className={classes["block__left"]}>
        <div className={classes["block__left-location"]}>
          <label>{t("adres")}:</label>
          <p>{getLocalized(data, "location")}</p>
        </div>
        <div className={classes["block__left-email"]}>
          <label>{t("email")}:</label>
          <p>{data.email}</p>
        </div>
        <div className={classes["block__left-workMode"]}>
          <p className={classes["block__left-workMode_label"]}>
            {t("workday")}:
          </p>
          <div className={classes["block__left-workMode_items"]}>
            {time.map((workMode) => (
              <div key={workMode.day}>
                <div
                  className={
                    workMode.weak && classes["block__left-workMode_items-weak"]
                  }
                >
                  <p>{workMode.day}</p>
                </div>
                <p>{workMode.time}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={classes["block__right"]}>
        <h5>{t("map")}</h5>
        <div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d964.1884181538348!2d69.22830119837575!3d41.298403036623526!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38ae8bb29be628a9%3A0xc57e713475edfbcc!2z0I7Qt9Cx0LXQutC40YHRgtC-0L0gItCQ0LTQvtC70LDRgiIg0YHQvtGG0LjQsNC7LdC00LXQvNC-0LrRgNCw0YLQuNC6INC_0LDRgNGC0LjRj9GB0Lg!5e0!3m2!1sen!2s!4v1718212001528!5m2!1sen!2s"
            height="500"
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </Container>
  );
};

export default WorkMode;
