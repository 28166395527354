import { useEffect, useState } from "react";
import axios from "axios";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useTranslation } from "react-i18next";

import Title from "../Title";
import Container from "../../layout/Container";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import classes from "./ActivePeople.module.scss";
import { Link } from "react-router-dom";

const ActivePeople = () => {
  const [data, setData] = useState(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const handleGetData = async () => {
      const res = await axios.get("https://api.adolat.uz/party_activists");
      setData(res.data);
    };
    handleGetData();
  }, []);

  function getFirstSixWords(text) {
    const words = text.split(" ");
    return words.slice(0, 6).join(" ") + `...`;
  }

  const getLocalizedText = (item, field) => {
    const localizedField = `${field}_${i18n.language}`;
    const defaultField = `${field}_uz`;
    return item[localizedField] || item[defaultField];
  };

  return (
    <div className={classes.active} data-aos="fade-up">
      <Container className={classes.active__container}>
        <Title data-aos="fade-right" className={classes.active__title}>
          {t("activepeople")}
        </Title>
        <Swiper
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            480: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            1440: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
            1750: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
            1910: {
              slidesPerView: 5,
            },
          }}
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
          slidesPerView={5}
          spaceBetween={30}
          navigation
          loop={true}
          speed={1000}
          autoplay={true}
        >
          {Array.isArray(data) &&
            data.map((item) => (
              <SwiperSlide
                className={classes["active-people__inner"]}
                key={item.id}
              >
                <div className={classes["active-people__item"]}>
                  <Link to={item.link}>
                    <img
                      src={item.image}
                      alt={getLocalizedText(item, "title")}
                    />
                    <div className={classes["active-people__info"]}>
                      <h3>{getLocalizedText(item, "title")}</h3>
                      <p>
                        {getFirstSixWords(getLocalizedText(item, "position"))}
                      </p>
                    </div>
                  </Link>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
      </Container>
    </div>
  );
};

export default ActivePeople;
