import { Helmet } from "react-helmet";
import React, { useRef, useEffect, useState, useCallback } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import DOMPurify from "dompurify";
import Breadcrumbs from "../../components/Breadcrumbs";
import Container from "../../layout/Container";
import Title from "../../components/Title";
import "./style.css";

const HistoryTimeline = () => {
  const [data, setData] = useState(null);
  const historyContainerRef = useRef(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchHistoryData = async () => {
      try {
        const res = await axios.get("https://api.adolat.uz/party_history/");
        const sortedData = res.data.sort((a, b) => a.year - b.year);
        setData(sortedData);
      } catch (error) {
        console.log("Error fetching history data:", error);
      }
    };
    fetchHistoryData();
  }, []);

  const debounce = useCallback((func, wait) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const container = historyContainerRef.current;
      if (container) {
        const timelinePoints = container.querySelectorAll(".timelinepoint");
        timelinePoints.forEach((point, index) => {
          const rect = point.getBoundingClientRect();
          if (index === 0 || rect.top < 350) {
            point.classList.add("filled");
          } else {
            point.classList.remove("filled");
          }
        });
      }
    };

    const debouncedScrollHandler = debounce(handleScroll, 100);

    window.addEventListener("scroll", debouncedScrollHandler);
    handleScroll();

    return () => window.removeEventListener("scroll", debouncedScrollHandler);
  }, [debounce]);

  const breadcrumbItems = [
    { name: t("HomePage"), link: "/" },
    { name: t("ThePartyHistoriy") },
  ];

  const decodeHtml = (html) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };

  const removeBrTags = (html) => html.replace(/<br\s*\/?>/gi, " ");

  const HTMLContent = ({ content }) => {
    const decodedContent = decodeHtml(content);
    const contentWithoutBr = removeBrTags(decodedContent);
    const sanitizedContent = DOMPurify.sanitize(contentWithoutBr);

    return (
      <div
        className="custom-html-content"
        style={{
          fontSize: "18px",
          lineHeight: "1.5",
          color: "#74767b",
          fontFamily: `"Montserrat", sans-serif`,
        }}
        dangerouslySetInnerHTML={{ __html: sanitizedContent }}
      />
    );
  };

  const getDescription = (item, field) => {
    const localizedField = `${field}_${i18n.language}`;
    const defaultField = `${field}_uz`;
    return item[localizedField] || item[defaultField];
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("ThePartyHistoriy")}</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <Container className="block_container">
        <div className="ab-history bg-white hidden xl:block">
          <div className="m11002">
            <Breadcrumbs items={breadcrumbItems} />
            <div className="grid grid-cols-2 my-20">
              <div className="col-span-1">
                <Title>{t("ThePartyHistoriy")}</Title>
              </div>
            </div>
            <div
              className="flex flex-col items-start relative"
              ref={historyContainerRef}
            >
              <div className="Line absolute top-0 bottom-64 left-1/2 transform -translate-x-1/2 w-1.5 flex items-start justify-center z-0">
                <span className="palka bg-card-blue sticky top-28 z-10 w-full h-60"></span>
                <span className="bg-slate-300 flex w-full h-full absolute top-0 z-0"></span>
              </div>
              {Array.isArray(data) &&
                data
                  .filter((item) => item.year > 0)
                  .map((item) => (
                    <div
                      key={item.id}
                      className="timelinepoint tabs w-1/2 px-20 flex flex-col gap-5"
                    >
                      <div className="desc opacity-50">
                        <h2 className="text-7xl font-medium text-blue-500">
                          {item.year}
                        </h2>
                        <HTMLContent
                          className="desc"
                          content={getDescription(item, "description")}
                        />
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default HistoryTimeline;
