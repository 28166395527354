import React from "react";
import { useTranslation } from "react-i18next";

import classes from "./Card.module.scss";

const Card = ({ data }) => {
  const { t, i18n } = useTranslation();

  const getLocalizedData = (data) => {
    switch (i18n.language) {
      case "uz":
        return {
          fullName: data.full_name_uz,
          position: data.position_uz,
        };
      case "ru":
        return {
          fullName: data.full_name_ru,
          position: data.position_ru,
        };
      case "en":
        return {
          fullName: data.full_name_en,
          position: data.position_en,
        };
      default:
        return {
          fullName: data.full_name_uz,
          position: data.position_uz,
        };
    }
  };

  const localizedData = getLocalizedData(data);

  return (
    <div className={classes.card}>
      <div className={classes.imgContainer}>
        <img src={data.image} alt={localizedData.fullName} />
      </div>
      <h6 className={classes.fullname}>{localizedData.fullName}</h6>
      <p className={classes.position}>{localizedData.position}</p>
      <a
        className={classes.link}
        href={`${data.link}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span>
          {t("more")} <i className="fa-solid fa-arrow-right"></i>
        </span>
      </a>
    </div>
  );
};

export default Card;
