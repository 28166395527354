import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  FaFacebook,
  FaTelegram,
  FaRegCalendarAlt,
  FaEye,
} from "react-icons/fa";
import Menu from "../../components/Menu";
import Container from "../../layout/Container";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import classes from "./AnnouncementsDynamic.module.scss";

const AnnouncementsDynamic = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const [data, setData] = useState(null);

  useEffect(() => {
    const handleGetData = async () => {
      try {
        const res = await axios.get(
          `https://api.adolat.uz/announcements/${id}`
        );
        setData(res.data);
      } catch (error) {
        console.error(error);
      }
    };
    handleGetData();
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const getLocalizedData = (item, field) => {
    const localizedField = `${field}_${i18n.language}`;
    const defaultField = `${field}_uz`;
    return item[localizedField] || item[defaultField];
  };

  const currentUrl = window.location.href;
  const shareText = "Check out this news article!";

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("Announcements")}</title>
        <link rel="canonical" href={currentUrl} />
      </Helmet>
      <Container className={classes["news__content"]}>
        <div className={classes["news__left"]}>
          {data ? (
            <div className={classes["news__card"]} key={data.id}>
              <div className={classes["news__intro"]}>
                <h2 className={classes["news__title"]}>
                  {getLocalizedData(data, "title")}
                </h2>
                <div className={classes["news__items"]}>
                  <p className={classes["news__views"]}>
                    {data.views} <FaEye />
                  </p>
                  <p>
                    <FaRegCalendarAlt />
                    {formatDate(data.created_at)}
                  </p>
                </div>
              </div>
              <div>
                <Swiper
                  modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                  slidesPerView={1}
                  navigation
                  loop={true}
                  speed={1500}
                  autoplay={true}
                >
                  {Object.values(data.images).map((el) => (
                    <SwiperSlide
                      className={classes["news__slider"]}
                      key={el.id}
                    >
                      <img
                        className={classes["news__imgs"]}
                        src={el.image}
                        alt=""
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
              <div
                className={classes["news__text"]}
                dangerouslySetInnerHTML={{
                  __html: getLocalizedData(data, "description"),
                }}
              ></div>
            </div>
          ) : (
            <p>Loading...</p>
          )}
          <div className={classes["news__info"]}>
            <ul className={classes["news__socials"]}>
              <li>
                <a
                  href={`https://t.me/share/url?url=${encodeURIComponent(
                    currentUrl
                  )}&text=${encodeURIComponent(shareText)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaTelegram />
                </a>
              </li>
              <li>
                <a
                  href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                    currentUrl
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebook />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className={classes["news__right"]}>
          <div className={classes["content"]}>
            <Menu />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default AnnouncementsDynamic;
