import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import DOMPurify from "dompurify";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  FaFacebook,
  FaTelegram,
  FaRegCalendarAlt,
  FaEye,
} from "react-icons/fa";
import { useTranslation } from "react-i18next";
import Menu from "../../components/Menu";
import Container from "../../layout/Container";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import classes from "./News.module.scss";

const News = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const [data, setData] = useState(null);

  useEffect(() => {
    const handleGetData = async () => {
      try {
        const res = await axios.get(`https://api.adolat.uz/news/${id}`);
        setData(res.data);
      } catch (error) {
        console.error(error);
      }
    };
    handleGetData();
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const currentUrl = window.location.href;
  const shareText = data
    ? `${data.title_uz} - ${data.poster_image}`
    : t("Check out this news article!");

  const getLocalizedText = (item, field) => {
    const localizedField = `${field}_${i18n.language}`;
    const defaultField = `${field}_uz`;
    return item[localizedField] || item[defaultField];
  };

  const decodeHtml = (html) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };

  const removePreTags = (html) => {
    return html.replace(/<pre[^>]*>|<\/pre>/g, "");
  };

  const HTMLContent = ({ content }) => {
    const decodedContent = decodeHtml(content);
    const sanitizedContent = DOMPurify.sanitize(removePreTags(decodedContent));

    return (
      <div
        className="custom-html-content"
        style={{
          fontSize: "18px",
          lineHeight: "1.5",
          fontFamily: `"Montserrat", sans-serif`,
        }}
        dangerouslySetInnerHTML={{ __html: sanitizedContent }}
      />
    );
  };

  return (
    <div className={classes["news"]}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data ? getLocalizedText(data, "title") : t("News")}</title>
        <link rel="canonical" href={currentUrl} />
        <meta property="og:description" content={shareText} />
      </Helmet>

      <Container className={classes["news__content"]}>
        <div className={classes["news__left"]}>
          {data ? (
            <div className={classes["news__card"]} key={data.id}>
              <div className={classes["news__intro"]}>
                <h2 className={classes["news__title"]}>
                  {getLocalizedText(data, "title")}
                </h2>
                <div className={classes["news__items"]}>
                  <p className={classes["news__views"]}>
                    {data.views} <FaEye />
                  </p>
                  <p>
                    <FaRegCalendarAlt />
                    {data.created
                      ? formatDate(data.created)
                      : formatDate(data.created_at)}
                  </p>
                </div>
              </div>
              {data.images && data.images.length > 0 && (
                <Swiper
                  className={classes["news__inner"]}
                  modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                  slidesPerView={1}
                  navigation
                  loop={true}
                  speed={2500}
                  autoplay={true}
                >
                  {data.images.map((el) => (
                    <SwiperSlide
                      className={classes["news__slider"]}
                      key={el.id}
                    >
                      <img
                        className={classes["news__imgs"]}
                        src={el.image}
                        alt="news"
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              )}
              <HTMLContent
                className={classes["news__text"]}
                content={getLocalizedText(data, "description")}
              />
            </div>
          ) : (
            ""
          )}
          <div className={classes["news__info"]}>
            <ul className={classes["news__socials"]}>
              <li>
                <a
                  href={`https://t.me/share/url?url=${encodeURIComponent(
                    currentUrl
                  )}&text=${encodeURIComponent(shareText)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaTelegram />
                </a>
              </li>
              <li>
                <a
                  href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                    currentUrl
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebook />
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className={classes["news__right"]}>
          <div className={classes["content"]}>
            <Menu />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default News;
