import React, { useState, useEffect } from "react";
import { TiArrowSortedDown } from "react-icons/ti";
import classNames from "classnames";
import axios from "axios";

import classes from "./NewsType.module.scss";
import { useTranslation } from "react-i18next";

const NewsType = ({
  selectedCategory,
  setSelectedCategory,
  isOpen,
  onToggle,
}) => {
  const { t, i18n } = useTranslation();
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get("https://api.adolat.uz/category/");
        setCategories([
          {
            id: "all",
            name_uz: t("AllCategory"),
            name_ru: t("AllCategory"),
            name_en: t("AllCategory"),
          },
          ...response.data,
        ]);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, [t]);

  const handleCategorySelect = (category) => {
    setSelectedCategory(category); // Pass the whole category object
    onToggle();
  };

  const getRegionName = (item) => {
    if (!item) return t("AllCategory");
    switch (i18n.language) {
      case "uz":
        return item.name_uz;
      case "ru":
        return item.name_ru;
      case "en":
        return item.name_en;
      default:
        return item.name_uz;
    }
  };

  const selectedCategoryName = categories.find(
    (region) => region.id === selectedCategory.id
  );

  return (
    <div className={classes["dropdown"]}>
      <button onClick={onToggle} className={classes["dropdown-toggle"]}>
        {selectedCategory.id === "all"
          ? t("AllCategory")
          : getRegionName(selectedCategoryName)}
        <TiArrowSortedDown
          className={classNames(
            classes["dropdown-icon"],
            isOpen ? classes["rotate"] : ""
          )}
        />
      </button>
      {isOpen && (
        <ul className={classes["dropdown-menu"]}>
          {categories.map((category) => (
            <li
              key={category.id}
              onClick={() => handleCategorySelect(category)}
              className={classes["dropdown-item"]}
            >
              {getRegionName(category)}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default NewsType;
