import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Container from "../../layout/Container";
import img1 from "./img/1.png";
import img2 from "./img/2.png";
import img3 from "./img/3.png";
import img4 from "./img/4.png";
import classes from "./Statistics.module.scss";

const durationValues = [1000, 1500, 2000, 3000];

const Statistics = () => {
  const { t } = useTranslation();
  const [item, setItem] = useState(null);

  useEffect(() => {
    const handleGetData = async () => {
      try {
        const res = await axios.get("https://api.adolat.uz/about/");
        setItem(res.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    handleGetData();
  }, []);

  if (!item) {
    return <div>Loading...</div>;
  }

  const data = [
    { icon: img1, title: t("subsed"), num: item[0]?.member || 0 },
    { icon: img2, title: t("starterparty"), num: item[0]?.bpt || 0 },
    { icon: img3, title: t("omqp"), num: item[0]?.deputy_count || 0 },
    { icon: img4, title: t("mkds"), num: item[0]?.local_deputy_count || 0 },
  ];

  return (
    <div className={classes["statistic"]}>
      <Container className={classes["statistic-content"]}>
        {data.map((statistic, index) => (
          <StatisticItem
            key={statistic.title}
            icon={statistic.icon}
            title={statistic.title}
            num={statistic.num}
            duration={durationValues[index % durationValues.length]}
          />
        ))}
      </Container>
    </div>
  );
};

const StatisticItem = ({ icon, title, num, duration }) => {
  const [count, setCount] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !isVisible) {
          let start = 0;
          const increment = num / (duration / 50);

          const counter = setInterval(() => {
            start += increment;
            if (start >= num) {
              start = num;
              clearInterval(counter);
            }
            setCount(Math.round(start));
          }, 50);

          setIsVisible(true);
        }
      },
      { threshold: 0.1 }
    );

    const current = ref.current;
    if (current) {
      observer.observe(current);
    }

    return () => {
      if (current) {
        observer.unobserve(current);
      }
    };
  }, [num, isVisible, duration]);

  return (
    <div
      className={classes["statistic-item"]}
      ref={ref}
      data-aos="fade-up"
      data-aos-duration={duration}
    >
      <p className={classes["statistic-icon"]}>
        <img src={icon} alt={title} />
      </p>
      <div className={classes["items"]}>
        <p className={classes["statistic-num"]}>{count}</p>
        <p className={classes["statistic-title"]}>{title}</p>
      </div>
    </div>
  );
};

export default Statistics;
