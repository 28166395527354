import axios from "axios";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Card from "./components/Card";
import Container from "../../layout/Container";
import Title from "../../components/Title";
import Breadcrumbs from "../../components/Breadcrumbs";
import classes from "./PartyProjects.module.scss";

const PartyProjects = () => {
  const { t } = useTranslation();
  const [data, setData] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
    const handleGetData = async () => {
      const res = await axios.get("https://api.adolat.uz/party_projects/");
      setData(res.data);
    };
    handleGetData();
  }, []);
  const breadcrumbItems = [
    { name: t("HomePage"), link: "/" },
    { name: t("PartyProjects") },
  ];

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("PartyProjects")}</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <Container className={classes["container"]}>
        <Breadcrumbs items={breadcrumbItems} />
        <Title>{t("PartyProjects")}</Title>
        <div className={classes["container__content"]}>
          {Array.isArray(data) &&
            data.map((item) => <Card key={item.id} data={item} />).reverse()}
        </div>
      </Container>
    </>
  );
};

export default PartyProjects;
