import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import axios from "axios";
import Container from "../../layout/Container";
import Title from "../Title";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import classes from "./Gallery.module.scss";

const Gallery = () => {
  const { t, i18n } = useTranslation();
  const [dataImage, setDataImage] = useState(null);
  const [dataVideo, setDataVideo] = useState(null);

  useEffect(() => {
    const handleDataImage = async () => {
      const res = await axios.get("https://api.adolat.uz/gallery_img");
      setDataImage(res.data);
    };
    handleDataImage();
  }, []);

  useEffect(() => {
    const handleGetVideo = async () => {
      const res = await axios.get("https://api.adolat.uz/gallery_video");
      setDataVideo(res.data);
    };
    handleGetVideo();
  }, []);

  const getYouTubeEmbedUrl = (url) => {
    const videoId = url.split("v=")[1]?.split("&")[0];
    return `https://www.youtube.com/embed/${videoId}`;
  };
  const getLocalizedText = (item, field) => {
    const localizedField = `${field}_${i18n.language}`;
    const defaultField = `${field}_uz`;
    return item[localizedField] || item[defaultField];
  };

  return (
    <div className={classes["gallery"]}>
      <Container className={classes["gallery__container"]}>
        <Title dataaos="fade-up">{t("mediagallery")}</Title>
        <div className={classes["gallery__content"]}>
          <div className={classes["gallery__left"]} data-aos="fade-up">
            <Swiper
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              slidesPerView={1}
              navigation
              pagination={{ clickable: true }}
            >
              {Array.isArray(dataImage) &&
                dataImage
                  .sort((a, b) => b.id - a.id)
                  .map((galleryImage) => (
                    <SwiperSlide
                      key={galleryImage.id}
                      className={classes["swiper-slide"]}
                    >
                      <div className={classes["gallery__items"]}>
                        <img src={galleryImage.image} alt="" />
                        <div className={classes["gallery__bg-left"]}>
                          <div className={classes["gallery__bg-left_item"]}>
                            <p>{getLocalizedText(galleryImage, "title")}</p>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
            </Swiper>
          </div>
          <div className={classes["gallery__right"]} data-aos="fade-up">
            <Swiper
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              slidesPerView={1}
              navigation
              pagination={{ clickable: true }}
            >
              {Array.isArray(dataVideo) &&
                dataVideo
                  .sort((a, b) => b.id - a.id)
                  .map((galleryVideo) => (
                    <SwiperSlide
                      className={classes["swiper-slide"]}
                      key={galleryVideo.id}
                    >
                      <div className={classes["gallery__items"]}>
                        <a
                          target="_blank"
                          href={galleryVideo.video}
                          rel="noopener noreferrer"
                        >
                          <iframe
                            width="100%"
                            height="500"
                            src={getYouTubeEmbedUrl(galleryVideo.video)}
                            title={getLocalizedText(galleryVideo, "title")}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          ></iframe>
                        </a>
                        <div className={classes["gallery__bg-right"]}>
                          <div>
                            <p>{getLocalizedText(galleryVideo, "title")}</p>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
            </Swiper>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Gallery;
