import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import React, { useEffect } from "react";
import Slider from "../../components/Slider";
import WorkMode from "./components/WorkMode";
import Form from "./components/Form";
import Map from "./components/Map";

const Contact = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("leadership")}</title>
        <link rel="canonical" href="/contact" />
      </Helmet>
      <Slider />
      <WorkMode />
      <Map />
      <Form />
    </div>
  );
};

export default Contact;
