import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaRegCalendarAlt, FaArrowRight, FaEye } from "react-icons/fa";
import { Link } from "react-router-dom";
import classNames from "classnames";
import DOMPurify from "dompurify";
import { useTranslation } from "react-i18next";

import classes from "./Card.module.scss";

const Card = ({ element, index }) => {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState("all");
  const [selectedCategory, setSelectedCategory] = useState({
    id: "all",
    name: t("AllCategory"),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleGetData = async () => {
      try {
        const newsResponse = await axios.get("https://api.adolat.uz/news");
        setData(newsResponse.data);
      } catch (error) {
        console.error("Error fetching news data:", error);
      }
    };
    handleGetData();
  }, []);

  const handleViewIncrement = async (newsId) => {
    try {
      const updatedData = data.map((item) =>
        item.id === newsId ? { ...item, views: item.views + 1 } : item
      );
      setData(updatedData);
      await axios.patch(`https://api.adolat.uz/news/${newsId}`, {
        views: updatedData.find((item) => item.id === newsId).views,
      });
    } catch (error) {
      console.error("Error handling view count:", error);
    }
  };

  useEffect(() => {
    if (data.length > 0) {
      const filtered = data.filter((item) => {
        const regionMatch =
          selectedRegion === "all" ||
          item.region === parseInt(selectedRegion, 10);
        const categoryMatch =
          selectedCategory.id === "all" ||
          item.category === parseInt(selectedCategory.id, 10);
        const searchMatch =
          !searchValue ||
          (getLocalizedText(item, "title") &&
            getLocalizedText(item, "title")
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (getLocalizedText(item, "description") &&
            getLocalizedText(item, "description")
              .toLowerCase()
              .includes(searchValue.toLowerCase()));

        return regionMatch && categoryMatch && searchMatch;
      });
      setFilteredData(filtered);
    }
  }, [searchValue, selectedCategory, data, selectedRegion]);

  const durationValues = (index) => [1000, 2000, 3000][index % 3];
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  };

  const getFirstWords = (text, wordCount) => {
    if (!text) return "";
    const cleanedText = text
      .replace(/<br\s*\/?>/gi, " ")
      .replace(/\s+/g, " ")
      .trim();
    const words = cleanedText.split(" ");
    const firstWords = words.slice(0, wordCount).join(" ");
    return words.length > wordCount ? `${firstWords}...` : firstWords;
  };

  const getLocalizedText = (item, field) => {
    const localizedField = `${field}_${i18n.language}`;
    const defaultField = `${field}_uz`;
    return item[localizedField] || item[defaultField];
  };

  const decodeHtml = (html) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };

  const HTMLContent = ({ content }) => {
    const decodedContent = decodeHtml(content);
    const sanitizedContent = DOMPurify.sanitize(decodedContent);

    return (
      <div
        className="custom-html-content"
        style={{
          fontSize: "18px",
          lineHeight: "1.5",
          color: "#74767b",
          fontFamily: `"Montserrat", sans-serif`,
        }}
        dangerouslySetInnerHTML={{ __html: sanitizedContent }}
      />
    );
  };

  return (
    <div
      data-aos="fade-up"
      data-aos-duration={durationValues(index)}
      key={index}
      className={classNames(classes["news-content__items"])}
    >
      <figure>
        <img
          src={element.poster_image}
          alt={getLocalizedText(element, "title")}
        />
      </figure>
      <div className={classes["body"]}>
        <h3>{getFirstWords(getLocalizedText(element, "title"), 5)}</h3>
        <p style={{ marginTop: "20px" }} className={classes["calendar"]}>
          <FaRegCalendarAlt />
          {element.created
            ? formatDate(element.created)
            : formatDate(element.created_at)}
        </p>
        <HTMLContent
          content={getFirstWords(getLocalizedText(element, "description"), 10)}
        />
        <p className={classes["news__views"]}>
          {element.views} <FaEye />
        </p>
        <Link
          to={`/news/${element.id}`}
          onClick={() => handleViewIncrement(element.id)}
        >
          {t("more")} <FaArrowRight />
        </Link>
      </div>
    </div>
  );
};

export default Card;
