import { useTranslation } from "react-i18next";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Container from "../../layout/Container";
import Card from "./components/Card";
import Title from "../../components/Title";
import Breadcrumbs from "../../components/Breadcrumbs";
import Menu from "../../components/Menu";
import classes from "./PoliticalCouncil.module.scss";

const PoliticalCouncil = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleGetData = async () => {
      const res = await axios.get("https://api.adolat.uz/party_leader/");
      setData(res.data);
    };
    handleGetData();
  }, []);

  const breadcrumbItems = [
    { name: t("HomePage"), link: "/" },
    { name: t("partyboss") },
  ];

  return (
    <Container className={classes["container"]}>
      <div className={classes["row"]}>
        <div className={classes["w-70"]}>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{t("partyboss")}</title>
            <link rel="canonical" href="http://mysite.com/example" />
          </Helmet>
          <Breadcrumbs items={breadcrumbItems} />
          <>
            <Title className={classes["mb-3"]}>{t("partyboss")}</Title>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {data
                .sort((a, b) => a.index - b.index)
                .map((item) => (
                  <Card key={item.id} data={item} />
                ))}
            </div>
          </>
        </div>
        <div className={classes["w-30"]}>
          <Menu />
        </div>
      </div>
    </Container>
  );
};

export default PoliticalCouncil;
