import React, { useEffect, useRef } from "react";
import classes from "./X.module.scss";

const TwitterTimeline = () => {
  const timelineRef = useRef(null);

  useEffect(() => {
    if (window.twttr && window.twttr.widgets) {
      window.twttr.widgets.load(timelineRef.current);
    }
  }, []);

  return (
    <div className={classes.X}>
      <a
        className="twitter-timeline"
        href="https://twitter.com/sdp_Adolat"
        ref={timelineRef}
        data-height="500"
        data-width={"100%"}
      >
        Tweets by AdolatTv
      </a>
    </div>
  );
};

export default TwitterTimeline;
