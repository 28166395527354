import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Container from "../../layout/Container";
import logo from "../../images/loyiha1.png";
import classes from "./Projects.module.scss";

const Projects = () => {
  const { t } = useTranslation();
  return (
    <div className={classes["projects"]}>
      <Container className={classes["projects__item"]}>
        <Link
          to="/party-projects"
          data-aos="fade-right"
          className={classes["projects__text"]}
        >
          {t("partyprojects")}
        </Link>
        <div>
          <img
            src={logo}
            data-aos="fade-left"
            className={classes["projects__text-img"]}
            alt=""
          />
        </div>
      </Container>
    </div>
  );
};

export default Projects;
