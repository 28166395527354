import axios from "axios";
import { useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { FaCartArrowDown } from "react-icons/fa";
import { Icon } from "@mdi/react";
import { useTranslation } from "react-i18next";
import { mdiEye, mdiEyeOff } from "@mdi/js";
import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import { Cross as Hamburger } from "hamburger-react";
import classNames from "classnames";
import { Link, useLocation } from "react-router-dom";
import { FaTelegramPlane, FaInstagram, FaFacebook } from "react-icons/fa";
import { TiArrowSortedDown } from "react-icons/ti";
import Navbar from "./Navbar";
import TextToSpeech from "./TextToSpeech";
import Container from "../../layout/Container";
import { getItemsCount } from "../../redux/cart";
import Dropdown from "./Dropdown";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import "./Header.scss";
import classes from "./Header.module.scss";

const Header = () => {
  const [media, setMedia] = useState([
    {
      icon: <FaInstagram />,
      link: "https://www.instagram.com/adolat_sdp/",
    },
    {
      icon: <FaFacebook />,
      link: "https://www.facebook.com/sdpu.adolat",
    },
    { icon: <FaTelegramPlane />, link: "https://t.me/adolat_uz" },
  ]);
  const [isOpen, setIsOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [isOpenElection, setIsOpenElection] = useState(false);
  const [isGrayscale, setIsGrayscale] = useState(false);
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const itemsCount = useSelector(getItemsCount);
  const [logoData, setLogoData] = useState({
    logo: "",
  });
  const [election, setElection] = useState({
    election_uz: "",
    election_ru: "",
    election_en: "",
  });
  const StorePagePathName = pathname === "/store";
  const CartPagePathName = pathname === "/cart";
  const handleChangeColorBody = () => setIsGrayscale((prevState) => !prevState);
  const toggleDropdown = () => setIsOpen((prev) => !prev);
  const toggleDropdownElection = () => setIsOpenElection((prev) => !prev);
  const closeNavbar = () => setIsNavbarOpen(false);

  useEffect(() => {
    const handleGetData = async () => {
      const res = await axios.get("https://api.adolat.uz/organizations/");
      if (res.data.length > 0) {
        const data = res.data[0];
        setLogoData({
          logo: data.logo || "",
        });
      }
    };
    handleGetData();
  }, []);

  useEffect(() => {
    const handleGetData = async () => {
      try {
        const res = await axios.get("https://api.adolat.uz/about/");
        if (res.data.length > 0) {
          const data = res.data[0];
          setElection({
            election_uz: data.election_uz || "",
            election_ru: data.election_ru || "",
            election_en: data.election_en || "",
          });
        }
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };

    handleGetData();
  }, []);

  useEffect(() => {
    if (isGrayscale) {
      document.body.classList.add("grayscale");
    } else {
      document.body.classList.remove("grayscale");
    }
  }, [isGrayscale]);

  useEffect(() => {
    if (isNavbarOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isNavbarOpen]);
  const handleScroll = () => {
    const scrollTop = window.scrollY;
    setIsSticky(scrollTop > 1);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const getLocalized = (item, field) => {
    const localizedField = `${field}_${i18n.language}`;
    const defaultField = `${field}_uz`;
    return item[localizedField] || item[defaultField];
  };

  return (
    <header
      className={classNames(classes["navbar"], {
        [classes["navbar--sticky"]]: isSticky,
      })}
    >
      <div className={classes["header-content"]}>
        <Container className={classes["header-content__top"]}>
          <div className={classes["header-content__settings"]}>
            <TextToSpeech />
            <p
              onClick={handleChangeColorBody}
              className={classes["header-content__icon"]}
            >
              <Icon
                path={isGrayscale ? mdiEyeOff : mdiEye}
                style={{
                  marginRight: "5px",
                  cursor: "pointer",
                  color: "white",
                }}
                size={1.5}
              />
            </p>
          </div>
          <ul className={classes["header-content__social"]}>
            {media &&
              media.map((item) => (
                <li key={item.link}>
                  <a
                    rel="noreferrer"
                    className={classes["header-content__social-icon"]}
                    href={item.link}
                    target="_blank"
                  >
                    {item.icon}
                  </a>
                </li>
              ))}
          </ul>
          <Dropdown />
        </Container>
      </div>
      <nav className={classes["header-items"]}>
        <Container className={classes["header-items__block"]}>
          <Link className={classes["header__item_logo"]} to="/">
            <img
              className={classes["header-items__logo"]}
              src={logoData.logo}
              alt="logo"
            />
          </Link>
          {!StorePagePathName && !CartPagePathName && (
            <ul className={classes["header-items__list"]}>
              <li>
                <Menu
                  menuButton={
                    <MenuButton
                      onClick={toggleDropdown}
                      className={classes["header-items__link"]}
                    >
                      {t("AboutTheSite")}
                      <TiArrowSortedDown
                        className={classNames(
                          classes["dropdown-icon"],
                          isOpen ? classes["rotate"] : ""
                        )}
                      />
                    </MenuButton>
                  }
                >
                  <Link to="/party-history">
                    <MenuItem className={classes["header__links"]}>
                      {t("ThePartyHistoriy")}
                    </MenuItem>
                  </Link>
                  <Link to="/deputs">
                    <MenuItem className={classes["header__links"]}>
                      {t("deputs")}
                    </MenuItem>
                  </Link>
                  <Link to="/political-council">
                    <MenuItem className={classes["header__links"]}>
                      {t("partyboss")}
                    </MenuItem>
                  </Link>
                  <Link to="/party-projects">
                    <MenuItem className={classes["header__links"]}>
                      {t("PartyProjects")}
                    </MenuItem>
                  </Link>
                </Menu>
              </li>
              <li>
                <Link className={classes["header-items__link"]} to="/news">
                  {t("TheNews")}
                </Link>
              </li>
              <li>
                <Menu
                  menuButton={
                    <MenuButton
                      onClick={toggleDropdownElection}
                      className={classes["header-items__link"]}
                    >
                      {t("TheElection")}
                      <TiArrowSortedDown
                        className={classNames(
                          classes["dropdown-icon"],
                          isOpenElection ? classes["rotate"] : ""
                        )}
                      />
                    </MenuButton>
                  }
                >
                  <Link to="/election">
                    <MenuItem className={classes["header__links"]}>
                      {getLocalized(election, "election")}
                    </MenuItem>
                  </Link>
                  <Link to="/election-program">
                    <MenuItem className={classes["header__links"]}>
                      {t("camprog")}
                    </MenuItem>
                  </Link>
                  <Link to="/candidates">
                    <MenuItem className={classes["header__links"]}>
                      {t("condidantes")}
                    </MenuItem>
                  </Link>
                </Menu>
              </li>
              <li>
                <Link className={classes["header-items__link"]} to="/contact">
                  {t("Contact")}
                </Link>
              </li>
            </ul>
          )}
          <div className={classes["header-items__buttons"]}>
            {(StorePagePathName || CartPagePathName) && (
              <Link className={classes["header-items__cart"]} to="/cart">
                <FaCartArrowDown />
                {Boolean(itemsCount) && <p>{itemsCount}</p>}
              </Link>
            )}
            <Link className={classes["header-items__donate"]} to="/sponsorship">
              {t("Donate")}
            </Link>
            {!StorePagePathName && !CartPagePathName ? (
              <a
                rel="noreferrer"
                href="https://azolik.adolat.uz/"
                target="_blank"
                className={classes["header-items__sign_k"]}
              >
                {t("HeaderLogin")}
              </a>
            ) : (
              ""
            )}
            <button
              className={classNames(classes["header-items__buttons-btn"], {
                [classes["header-items__buttons-btn_open"]]: isNavbarOpen,
              })}
            >
              <Hamburger toggled={isNavbarOpen} toggle={setIsNavbarOpen} />
            </button>
          </div>
        </Container>
      </nav>
      {isNavbarOpen && <Navbar onClose={closeNavbar} />}
    </header>
  );
};

export default Header;
