import axios from "axios";
import { useTranslation } from "react-i18next";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import React, { useEffect, useState } from "react";
import { FaTelegram } from "react-icons/fa";
import Container from "../../../../layout/Container";
import Title from "../../../../components/Title";
import MapSvg from "./MapSvg";
import "./index.css";
import "react-tabs/style/react-tabs.css";
import classes from "./Map.module.scss";
const region = [
  { id: 30, state: "andijon" },
  { id: 41, state: "xorazm" },
  { id: 31, state: "buxoro" },
  { id: 38, state: "surxondaryo" },
  { id: 1, state: "qashqadaryo" },
  { id: 34, state: "navoiy" },
  { id: 36, state: "samarqand" },
  { id: 33, state: "jizzax" },
  { id: 28, state: "sirdaryo" },
  { id: 39, state: "toshkent-v" },
  { id: 40, state: "toshkent" },
  { id: 32, state: "fargona" },
  { id: 35, state: "namangan" },
  { id: 29, state: "qoraqalpoq" },
];

const Map = () => {
  const { t, i18n } = useTranslation();
  const [background, setBackground] = useState([]);
  const [backgroundUrl, setBackgroundUrl] = useState("");
  const [filteredRegion, setFilteredRegion] = useState(null);
  const [regionList, setRegionList] = useState([]);
  const [activePath, setActivePath] = useState(region[10].state);
  const [activeTab, setActiveTab] = useState(0);
  const [areas, setAreas] = useState([]);
  const [deputy, setDeputy] = useState([]);
  const [filteredDeputies, setFilteredDeputies] = useState([]);
  const [youth, setYouth] = useState([]);
  const [location, setLocation] = useState([]);
  const [locationParliament, setLocationParliament] = useState([]);

  useEffect(() => {
    const handleGetBackground = async () => {
      try {
        const res = await axios.get("https://api.adolat.uz/background/");
        setBackground(res.data);
      } catch (error) {
        console.log("Error fetching background:", error);
      }
    };
    handleGetBackground();
  }, []);

  useEffect(() => {
    const handleGetRegion = async () => {
      const res = await axios.get("https://api.adolat.uz/regions/");
      setRegionList(res.data);
    };
    handleGetRegion();
  }, []);

  useEffect(() => {
    const handleGetDeputy = async () => {
      try {
        const res = await axios.get("https://api.adolat.uz/deputies/");
        setDeputy(res.data);
      } catch (error) {
        console.log("Error fetching deputies:", error);
      }
    };
    handleGetDeputy();
  }, []);

  useEffect(() => {
    const handleGetYouth = async () => {
      try {
        const res = await axios.get("https://api.adolat.uz/central_hardware/");
        setYouth(res.data);
      } catch (error) {
        console.log("Error fetching youth:", error);
      }
    };
    handleGetYouth();
  }, []);

  useEffect(() => {
    const handleGetAreas = async () => {
      try {
        const res = await axios.get("https://api.adolat.uz/areas/");
        setAreas(res.data);
      } catch (error) {
        console.log("Error fetching areas:", error);
      }
    };
    handleGetAreas();
  }, []);

  useEffect(() => {
    handleFilter();
  }, [areas, deputy, youth, activePath, activeTab]);

  const handleFilterRegion = (id) => {
    return regionList.find((item) => item.id === id);
  };

  useEffect(() => {
    if (regionList.length > 0) {
      const regionId = 30;
      const filtered = handleFilterRegion(regionId);
      setFilteredRegion(filtered);
    }
  }, [regionList]);

  const handleFilter = () => {
    let filteredData = [];
    if (activeTab === 0) {
      filteredData = areas.filter((area) => area.region === activePath);
    } else if (activeTab === 1) {
      filteredData = deputy.filter((item) => item.region === activePath);
      setLocation(filteredData);
    } else if (activeTab === 2) {
      filteredData = youth.filter((item) => item.region === activePath);
      setLocationParliament(filteredData);
    }
    setFilteredDeputies(filteredData);
  };

  const handleClick = (pathName) => {
    setActivePath(pathName);
    const backgroundItem = background.find((bg) => bg.region === pathName);
    setBackgroundUrl(backgroundItem ? backgroundItem.backgroundUrl : "");
  };
  const handleTabSelect = (index) => {
    setActiveTab(index);
  };

  const hasScrollableContent = filteredDeputies.length >= 3;

  function getTitle(item, field) {
    if (!item) return ""; // Ensure item exists before trying to access its fields
    const localizedField = `${field}_${i18n.language}`;
    const defaultField = `${field}_uz`;
    return item[localizedField] || item[defaultField] || "";
  }

  return (
    <section
      style={{
        background: `url(${
          backgroundUrl || "https://i.imgur.com/PGxp9zv.jpeg"
        }) no-repeat center center / cover`,
        width: "100%",
      }}
    >
      <Container>
        <Title dataaos="fade-right" className={classes["container__title"]}>
          {t("mapinteractive")}
        </Title>
        <Tabs onSelect={handleTabSelect}>
          <TabList className={classes["flex-md-column"]}>
            <Tab>{t("territory")}</Tab>
            <Tab>{t("deputs")}</Tab>
            <Tab>{t("youngparlament")}</Tab>
          </TabList>
          <TabPanel className={"container__items"}>
            <div data-aos="fade-up" className={classes["container"]}>
              <div className={classes["map"]}>
                <div
                  className={classes["map__left"]}
                  style={{ maxWidth: "500px" }}
                >
                  <MapSvg
                    activePath={activePath}
                    handleClick={handleClick}
                    region={region}
                  />
                </div>
                <div className={classes["map__right"]}>
                  {filteredDeputies.map((item) => (
                    <div
                      key={item.id}
                      className={classes["map__right-content"]}
                    >
                      <p className={classes["bold-location"]}>
                        {getTitle(item, "location")}
                      </p>
                      <div className={classes["map__right-content_top"]}>
                        <div>
                          <img
                            src={item.image}
                            alt={getTitle(item, "full_name_uz")}
                          />
                          <div>
                            <p className={classes["bold-name"]}>
                              {getTitle(item, "full_name")}
                            </p>
                            <br />
                            <p className={classes["bold-name"]}>
                              {getTitle(item, "position")}
                            </p>
                            <br />
                            <a
                              style={{ fontSize: "22px" }}
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item.telegram}
                            >
                              <FaTelegram />
                            </a>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={`mailto:${item.email}`}
                            >
                              {item.email}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel className={"container__items"}>
            <div data-aos="fade-up" className={classes["map"]}>
              <div
                className={classes["map__left"]}
                style={{ maxWidth: "500px" }}
              >
                <MapSvg
                  activePath={activePath}
                  handleClick={handleClick}
                  region={region}
                />
              </div>
              <div className={classes["map__right"]}>
                <div className={classes["map__right-content"]}>
                  <div>
                    <h4>
                      {getTitle(location[0], "location")}
                      {t("RegionDeputes")}
                    </h4>
                    <div
                      className={classes["map__right-items"]}
                      style={{
                        overflowY: hasScrollableContent ? "auto" : "hidden",
                      }}
                    >
                      {filteredDeputies.map((item) => (
                        <div
                          key={item.id}
                          className={classes["map__right-items_block"]}
                        >
                          <div>
                            <img src={item.image} alt={""} />
                          </div>
                          <div>
                            <p>{getTitle(item, "full_name")}</p>
                            <p>{getTitle(item, "position")}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel className={"container__items"}>
            <div data-aos="fade-up" className={classes["map"]}>
              <div
                className={classes["map__left"]}
                style={{ maxWidth: "500px" }}
              >
                <MapSvg
                  activePath={activePath}
                  handleClick={handleClick}
                  region={region}
                />
              </div>
              <div className={classes["map__right"]}>
                <div className={classes["map__right-content"]}>
                  <div>
                    <h4>
                      {getTitle(locationParliament[0], "location")}{" "}
                      {t("YouthParliament")}
                    </h4>
                    <div
                      className={classes["map__right-items"]}
                      style={{
                        overflowY: hasScrollableContent ? "auto" : "hidden",
                      }}
                    >
                      {filteredDeputies.map((item) => (
                        <div
                          key={item.id}
                          className={classes["map__right-items_block"]}
                        >
                          <div>
                            <img src={item.image} alt={""} />
                          </div>
                          <div>
                            <p>{getTitle(item, "title")}</p>
                            <p>{getTitle(item, "position")}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
        </Tabs>
      </Container>
    </section>
  );
};

export default Map;
